import { differenceInYears, max, parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import differenceInHours from 'date-fns/differenceInHours'

export type DateShortcut =
  | 'Today'
  | 'Today or Next Business Day'
  | 'This Week'
  | 'This Month'
  | 'Next 7 Days'
  | 'Next 30 Days'

export type DateRangeShortcut = {
  start: Date
  end: Date
}

export function getCurrentIsoDate(): string {
  return new Date().toISOString()
}

/**
 * Get current date as string in yyyy-MM-dd format.
 * @param isoDate optional ISO date to convert to date string
 * @returns string yyyy-MM-dd`
 * @example
 * getDateString('2021-08-01T00:00:00.000Z') // '2021-08-01'
 */
export function getDateString(isoDate?: string): string {
  const date = isoDate ? new Date(isoDate) : new Date()
  return date.toISOString().substring(0, 10)
}

export const getCurrentTime = () => Date.now()

export function formatDate(
  isoDate: string,
  dateFormat = 'MM/DD/YYYY',
) {
  if (!isoDate) return null
  try {
    if (dateFormat) {
      // Convert account setting date format to date-fns format.
      dateFormat = dateFormat
        .replace('DD', 'dd')
        .replace('YYYY', 'yyyy')
    }
    const date = new Date(
      Date.UTC(
        parseInt(isoDate.substring(0, 4)),
        parseInt(isoDate.substring(5, 7)) - 1, // The result is reduced by 1 because JS Date objects count months from 0 (January) to 11 (December).
        parseInt(isoDate.substring(8, 10)),
      ),
    )

    // Keep it in UTC to avoid timezone, off by 1 issues (e.g. with birth dates)
    return format(utcToZonedTime(date, 'UTC'), dateFormat ?? 'PP', {
      timeZone: 'UTC',
    })
  } catch (e) {
    console.error(e)
    return `Invalid date (${isoDate})`
  }
}

export function formatDateTime(
  isoDateTime: string,
  includeTime = false,
  overrideFormat?: string,
) {
  if (!isoDateTime) return null

  // Support 4 digit year values.
  if (isoDateTime.length === 4) {
    isoDateTime = `${isoDateTime}-01-01`
  }

  try {
    if (overrideFormat) {
      // Convert account setting date format to date-fns format.
      overrideFormat = overrideFormat
        .replace('DD', 'dd')
        .replace('YYYY', 'yyyy')
    }
    if (includeTime) {
      const parsedDateTime = parseISO(isoDateTime)
      return format(parsedDateTime, overrideFormat ?? 'PPp')
    } else {
      // Format date only.
      // Parse the string as a date in UTC time yyyy-MM-dd format.
      const parsedDateTime = new Date(
        Date.UTC(
          parseInt(isoDateTime.substring(0, 4)),
          parseInt(isoDateTime.substring(5, 7)) - 1, // The result is reduced by 1 because JS Date objects count months from 0 (January) to 11 (December).
          parseInt(isoDateTime.substring(8, 10)),
        ),
      )

      // Keep it in UTC to avoid timezone, off by 1 issues (e.g. with birth dates)
      return format(
        utcToZonedTime(parsedDateTime, 'UTC'),
        overrideFormat ?? 'PP',
        {
          timeZone: 'UTC',
        },
      )
    }
  } catch (e) {
    console.error(`${e} (${isoDateTime})`)
    return `Invalid date (${isoDateTime})`
  }
}

// Returns distance of time from now.
// Returns formatted date if more than 24 hours than now.
export function formatTimeAgo(
  startIsoDateTime: string,
  includeTime?: boolean,
  options?: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Locale
  },
) {
  if (!startIsoDateTime) return ''
  const startDate = new Date(startIsoDateTime)
  const hours = differenceInHours(Date.now(), startDate)

  return hours < 24
    ? `${formatDistanceToNow(startDate, options)} ago`
    : formatDateTime(startIsoDateTime, !!includeTime)
}

export function formatAge(startIsoDateTime: string) {
  if (!startIsoDateTime) return ''
  try {
    return differenceInYears(new Date(), new Date(startIsoDateTime))
  } catch {
    return ''
  }
}

export function dateRangeShortcut(
  shortcut: string,
  timezone?: string,
): DateRangeShortcut {
  const now = new Date()
  const start = new Date()
  const end = new Date()

  switch (shortcut) {
    case 'Today':
      start.setHours(0, 0, 0, 0)
      end.setHours(23, 59, 59, 999)
      break
    case 'Today or Next Business Day': {
      // Set 'start' to today
      start.setHours(0, 0, 0, 0)

      // Check what day today is (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const today = now.getDay()

      if (today === 5) {
        // If today is Friday, the next business day is Monday
        end.setDate(now.getDate() + 3) // Move to Monday
      } else if (today === 6) {
        // If today is Saturday, the next business day is Monday
        end.setDate(now.getDate() + 2) // Move to Monday
      } else {
        // For other weekdays (Sunday to Thursday), the next day is the next business day
        end.setDate(now.getDate() + 1)
      }
      end.setHours(23, 59, 59, 999)
      break
    }
    case 'This Week':
      start.setDate(now.getDate() - now.getDay())
      start.setHours(0, 0, 0, 0)
      end.setDate(now.getDate() + (6 - now.getDay()))
      end.setHours(23, 59, 59, 999)
      break
    case 'This Month':
      start.setDate(1)
      start.setHours(0, 0, 0, 0)
      end.setMonth(now.getMonth() + 1)
      end.setDate(0)
      end.setHours(23, 59, 59, 999)
      break
    case 'Next 7 Days':
      start.setDate(now.getDate() + 1)
      start.setHours(0, 0, 0, 0)
      end.setDate(now.getDate() + 7)
      end.setHours(23, 59, 59, 999)
      break
    case 'Next 30 Days':
      start.setDate(now.getDate() + 1)
      start.setHours(0, 0, 0, 0)
      end.setDate(now.getDate() + 30)
      end.setHours(23, 59, 59, 999)
      break
    default:
      return {
        end: null,
        start: null,
      }
  }

  if (timezone) {
    start.setMinutes(start.getMinutes() + start.getTimezoneOffset())
    end.setMinutes(end.getMinutes() + end.getTimezoneOffset())
  }

  return {
    end,
    start,
  }
}

export function isoDateToDateString(isoDate: string) {
  return isoDate ? isoDate.substring(0, 10) : null
}

export function getLatestDate(dates: Date[]) {
  if (!dates) return null
  dates = dates.filter((date) => !isNaN(+date))

  if (dates.length === 0) return null
  return max(dates)
}
