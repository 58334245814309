import { Box, BoxProps, Checkbox, TextField } from '@mui/material'
import { grey } from '@mui/material/colors'
import { omit } from 'lodash'
import { useRouter } from 'next/router'
import React, { Ref } from 'react'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import remarkDirective from 'remark-directive'
import remarkExtendedTable, {
  extendedTableHandlers,
} from 'remark-extended-table'
import remarkGfm from 'remark-gfm'
import { commonRemarkDirectives } from 'utils/MarkdownUtil'

const MarkdownContent = React.forwardRef(function MarkdownContent(
  props: Omit<ReactMarkdownOptions, 'children'> & {
    component?: BoxProps['component']
    sx?: BoxProps['sx']
    value: string
    onClick?: BoxProps['onClick']
  },
  ref: Ref<HTMLDivElement>,
) {
  const { onClick, sx, ...markdownOptions } = props

  if (!props.value) {
    return null
  }

  return (
    <Box
      ref={ref}
      component={props.component}
      onClick={onClick}
      sx={{
        '& .MuiInputBase-input': {
          // Remove unnecessary margins for readOnly.
          '& > *:first-child': { mt: 0 },
          '& > *:last-child': { mb: 0 },
        },
        '& .MuiTextField-root.signature': {
          '& .MuiInputBase-input': {
            '&.Mui-disabled': {
              WebkitTextFillColor: ({ palette }) =>
                palette.text.primary,
              color: 'text.primary',
            },
            fontFamily: "'Oooh Baby', cursive",
            fontSize: '2.5em',
            py: 1,
            textIndent: 8, // Avoid clipping.
          },
          width: 1,
        },
        '& .banner': {
          '& > *:first-child:not(:last-child)': { mb: '.5em', mt: 0 },
          '& p': { my: '.5em' },
          borderColor: 'secondary.light',
          borderLeftStyle: 'solid',
          borderLeftWidth: 5,
          mb: 1,
          p: 1.5,
        },
        '& .card': {
          '& > *:first-of-type': {
            mt: 0,
          },
          '& > .field': { mb: 0 },
          border: '1px solid',
          borderColor: ({ palette }) =>
            palette.mode === 'dark' ? grey[800] : grey[300],
          mt: '-1px',
          p: 2,
        },
        '& .divided + .divided': {
          borderTop: '1px solid',
          borderTopColor: 'divider',
          mt: 1,
          pt: 1,
        },
        '& .field': {
          '& .label': { color: 'text.secondary' },
          '& .value': { fontWeight: 500 },
          '&.inline': {
            // Force all children to display inline.
            '& *': { display: 'inline' },
            // Add space label/value when inline.
            '& .value': { ml: 1 },
            gap: 1,
            mb: 0,
          },
          '&:last-of-type': { mb: 0 },
          mb: 3,
        },
        '& .fieldGroup > *': { ml: 7 },
        '& .fieldGroup > *:first-of-type': { ml: 0 },
        '& .grid': {
          '& .heading + *, & .heading + .heading, & .heading:first-child':
            {
              marginTop: 0,
            },
          '& > *': { width: `100%` },
          '& > .col-2': { width: '16.6%' },
          '& > .col-3': { width: '25%' },
          '& > .col-4': { width: '33.3%' },
          '& > .col-6': { width: '50%' },
          '& > .col-8': { width: '66.6%' },
          '& > .col-9': { width: '75%' },
          '& h1, h2, h3': {
            fontWeight: 500,
          },
          '& h1, h2, h3, h4, h5, h6': {
            '&:first-child': {
              marginTop: 0,
            },
            my: '1em',
          },
          '& h2': {
            '&.title .center': {
              textAlign: 'center',
            },
            '&.title .underline': {
              textDecoration: 'underline',
            },
          },
          '& h3': {
            borderBottom: 'solid 1px',
            borderBottomColor: 'divider',
            paddingBottom: 1,
          },
          '& h4, h5, h6': {
            fontWeight: 600,
          },
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'stretch',
        },
        '& .header': {
          color: 'text.secondary',
          fontSize: '.875em',
          fontWeight: 500,
        },
        '& .indentedBlock': { ml: 7 },
        '& .note': {
          color: 'text.secondary',
          fontSize: '.8125rem',
          mt: 3,
        },
        '& .pageHeader': {
          '& .accountLogo': {
            maxHeight: 48,
            maxWidth: 240,
            mb: 1,
            objectFit: 'contain',
            objectPosition: 'right center',
          },
          '& .secondary': {
            color: 'text.secondary',
          },
          '& .subtitle': {
            fontSize: '1.25em',
            fontWeight: 600,
            m: 0,
          },
          '& .title': {
            fontSize: '1.66em',
            fontWeight: 400,
            m: 0,
          },
          '&.divider': {
            borderBottom: '1px solid',
            borderBottomColor: 'divider',
          },
          '&.gutterBottom': {
            mb: 4,
          },
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          py: 0.5,
        },
        '& .row': {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        '& img': {
          objectFit: 'contain',
        },
        '& label': {
          display: 'block',
          fontWeight: 500,
        },
        '& p': {
          '&:first-of-type': { mt: 0 },
          '&:last-of-type': { mb: 0 },
          // Fix p margins within flex container.
          '+ p': { mt: 0 },
        },
        '& ul.contains-task-list': {
          '& .task-list-item': {
            '& .MuiCheckbox-root': {
              ml: -5.5,
              my: -0.2,
              py: 0,
            },
            paddingInlineStart: 5,
          },
          '&:has(.task-list-item)': {
            listStyleType: 'none',
            paddingInlineStart: 0,
          },
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        },
        hr: {
          borderBottom: '1px solid',
          borderColor: 'divider',
          borderTop: 0,
          my: 4,
        },
        strong: {
          fontWeight: 500,
        },
        table: {
          borderCollapse: 'collapse',
          fontSize: '.875em',
          mb: '1em !important',
          mt: '0.25em !important',
          tableLayout: 'fixed',
          width: '100%',
        },
        'tbody tr:nth-of-type(even)': {
          color: 'text.primary',
        },
        'tbody tr:nth-of-type(odd)': {
          bgcolor: 'action.hover',
          color: 'text.primary',
        },
        th: {
          fontWeight: 500,
        },
        'th, td': {
          border: '1px solid',
          borderColor: 'divider',
          p: 1,
        },
        thead: {
          bgcolor: 'transparent',
          color: 'text.primary',
        },
        ...sx,
      }}
    >
      <ReactMarkdown
        remarkPlugins={[
          remarkGfm,
          remarkDirective,
          commonRemarkDirectives,
          remarkExtendedTable,
        ]}
        remarkRehypeOptions={{
          handlers: Object.assign({}, extendedTableHandlers),
        }}
        linkTarget={(href = '') =>
          // Open relative links in same tab and external links in new tab.
          href.match(/^http|mailto|tel/) ? '_blank' : '_self'
        }
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // Adding this in order to inject a heading class to the heading components.
        // so we can '& .heading + .heading' in the sx prop.
        components={{
          a: MarkdownLink,
          h1: ({ node, ...props }) => (
            <h1 className="heading" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="heading" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="heading" {...props} />
          ),
          h4: ({ node, ...props }) => (
            <h4 className="heading" {...props} />
          ),
          h5: ({ node, ...props }) => (
            <h5 className="heading" {...props} />
          ),
          h6: ({ node, ...props }) => (
            <h6 className="heading" {...props} />
          ),
          input: ({ checked, type, children, className }) => {
            return type === 'checkbox' ? (
              <Checkbox
                className={className}
                checked={checked}
                color="default"
                disabled
              />
            ) : (
              <TextField
                className={className}
                disabled
                value={children}
              />
            )
          },
          ...markdownOptions.components,
        }}
        {...markdownOptions}
      >
        {props.value}
      </ReactMarkdown>
    </Box>
  )
})

export default MarkdownContent

export function MarkdownLink(props: BoxProps<'a'>) {
  const { href } = props
  const router = useRouter()
  const external = !!href?.match(/^http|mailto|tel/)

  return (
    <Box
      component="a"
      {...omit(props, 'node')}
      href={href}
      target={href?.match(/^http|mailto|tel/) ? '_blank' : '_self'}
      onClick={(e) => {
        if (external) {
          // Only use router for internal links.
          return
        }
        e.preventDefault()
        // Allows relative urls to work with Next router by
        // using the fully qualified href from the target element.
        router.push(e.currentTarget.href)
      }}
    />
  )
}
