import React from 'react'
import { OutlinedInput } from '@mui/material'
import {
  Field,
  FieldProps,
} from 'components/StoryPlayer/pages/PageForm'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import DateMaskInput from 'components/MaskInput/DateMaskInput'
import StoryFieldLabel from 'components/StoryFieldLabel'
import HelperBlocks from './HelperBlocks'
import { AccountSettings } from '@shared/constants'

// Support full and partial dates.
export type DateFormat =
  | AccountSettings['fieldFormatting']['date']
  | 'MM/YYYY'
  | 'MM.YYYY'
  | 'YYYY'

export interface DateField extends Field {
  type: 'date'
  format?: DateFormat
  placeholder?: string
  helperText?: string
  autoFocus?: boolean
}

interface StoryDateFieldProps extends DateField, FieldProps {
  format?: DateFormat
  disabled?: boolean
}

export default function StoryDateField(props: StoryDateFieldProps) {
  const {
    fieldState,
    label,
    format,
    required,
    helperBlocks,
    disableRequiredAsterisk,
    inputRef,
  } = props
  const placeholder = props.placeholder ?? format
  const { error } = fieldState
  const helperText = error?.message || props.helperText

  return (
    <FormControl fullWidth error={!!error} sx={{ gap: 1 }}>
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      <OutlinedInput
        inputRef={inputRef}
        color="secondary"
        placeholder={placeholder}
        autoFocus={props.autoFocus}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value ?? ''}
        disabled={props.disabled}
        inputComponent={DateMaskInput}
        inputProps={{
          inputMode: 'numeric',
          pattern: format,
        }}
        fullWidth
        error={!!error}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
