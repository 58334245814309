export function convertKgToLb(
  kg: number,
  decimalPlaces: number | false = 0,
) {
  const lb = kg * 2.205

  if (decimalPlaces === false) {
    return lb
  }

  return Number(lb.toFixed(decimalPlaces))
}

export function convertLbToKg(
  lb: number,
  decimalPlaces: number | false = 1,
) {
  const kg = lb / 2.205

  if (decimalPlaces === false) {
    return kg
  }

  return Number(kg.toFixed(decimalPlaces))
}

export function convertInToCm(
  inches: number,
  decimalPlaces: number | false = false,
): number {
  const cm = inches * 2.54

  if (decimalPlaces === false) {
    return cm
  }

  return Number(cm.toFixed(decimalPlaces))
}

export function convertCmToIn(
  cm: number,
  decimalPlaces: number | false = false,
): number {
  const inches = cm / 2.54

  if (decimalPlaces === false) {
    return inches
  }

  return Number(inches.toFixed(decimalPlaces))
}

export function convertInToFtIn(inches: number): {
  feet: number
  inches: number
} {
  return {
    feet: Math.floor(inches / 12),
    inches: Math.round(inches % 12),
  }
}

export function convertCmToFtIn(cm: number): {
  feet: number
  inches: number
} {
  const inches = convertCmToIn(cm)
  const ftIn = convertInToFtIn(inches)

  return ftIn
}

export function convertFtInToCm(
  feet: number,
  inches: number,
  decimalPlaces: number | false = 2,
): number {
  const totalInches = feet * 12 + inches
  const cm = convertInToCm(totalInches)

  if (decimalPlaces === false) {
    return cm
  }

  return Number(cm.toFixed(decimalPlaces))
}

export function convertCholesterolMgdlToMmol(
  mgdl: number,
  decimalPlaces: number | false = 2,
) {
  const mmol = mgdl / 38.67

  if (decimalPlaces === false) {
    return mmol
  }

  return Number(mmol.toFixed(decimalPlaces))
}

export function convertCholesterolMmolToMgdl(
  mmol: number,
  decimalPlaces: number | false = 0,
) {
  const mgdl = mmol * 38.67

  if (decimalPlaces === false) {
    return mgdl
  }

  return Number(mgdl.toFixed(decimalPlaces))
}

export function convertTriglyceridesMgdlToMmol(
  mgdl: number,
  decimalPlaces: number | false = 2,
) {
  const mmol = mgdl / 88.57

  if (decimalPlaces === false) {
    return mmol
  }

  return Number(mmol.toFixed(decimalPlaces))
}

export function convertTriglyceridesMmolToMgdl(
  mmol: number,
  decimalPlaces: number | false = 0,
) {
  const mgdl = mmol * 88.57

  if (decimalPlaces === false) {
    return mgdl
  }

  return Number(mgdl.toFixed(decimalPlaces))
}

export function convertGlucoseMgdlToMmol(
  mgdl: number,
  decimalPlaces: number | false = 2,
) {
  const mmol = mgdl / 18.02

  if (decimalPlaces === false) {
    return mmol
  }

  return Number(mmol.toFixed(decimalPlaces))
}

export function convertGlucoseMmolToMgdl(
  mmol: number,
  decimalPlaces: number | false = 0,
) {
  const mgdl = mmol * 18.02

  if (decimalPlaces === false) {
    return mgdl
  }

  return Number(mgdl.toFixed(decimalPlaces))
}

/*
Example callback function to transform the value (e.g., doubling the number)
const doubleValue = (value) => value * 2;

Example usage:
const originalString = "Maximum is 300 (kg)";
const updatedStringWithNewUnit = replaceNumberAndUnit(originalString, doubleValue, "lb");
console.log(updatedStringWithNewUnit); // Output: "Maximum is 600 (lb)"
*/

export function replaceNumberAndUnit(
  inputString: string,
  transformCallback: (value: number) => number | string,
  newUnit = null,
) {
  // Define the regex pattern to match a number followed by any unit in parentheses
  const regex = /(\d+)\s*([a-zA-Z]+\/?[a-zA-Z]*)/

  // Use the replace method with a callback function
  const replacedString = inputString.replace(
    regex,
    (match, number, unit) => {
      // Transform the number using the provided callback
      const transformedValue = transformCallback(Number(number))
      // Use the new unit if provided, otherwise keep the original unit
      const finalUnit = newUnit !== null ? newUnit : unit
      // Return the transformed value with the appropriate unit
      return `${transformedValue} ${finalUnit}`
    },
  )

  return replacedString
}
