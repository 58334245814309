import { Section } from '@shared/FormSchema'
import { Operator, Rules } from '@shared/rule-engine-types'
import { RuleField, SelectOption } from '@shared/rule-properties-util'
import { StoryConnectionRule } from 'components/StoryPlayer'
import {
  FormField,
  PageFormData,
} from 'components/StoryPlayer/pages/PageForm'
import { get, set, trim } from 'lodash'

export function getFormFieldFromRuleField(
  field: RuleField,
): FormField {
  const widget = field.ui?.widget
  const helperText = field.helperTextForPatient ?? field.helperText
  const formField: Pick<
    FormField,
    'beforeBlock' | 'label' | 'name' | 'required' | 'helperBlocks'
  > = {
    helperBlocks: helperText
      ? [{ content: helperText, type: 'text' }]
      : undefined,
    label: field.labelForPatient || field.label,
    name: field.name,
    required: field.required,
  }

  switch (field.type) {
    case 'select':
    case 'select-genes':
    case 'select-number':
    case 'radio': {
      const options = getOptionsForPatient(field.options)

      if (field.multiple) {
        return {
          ...formField,
          options: getOptionsForPatient(options) as {
            label: string
            value: string
          }[],
          type: 'checkbox',
        }
      }

      return {
        ...formField,
        columns:
          field.type === 'radio'
            ? (Math.max(4, options?.length) as 1 | 2 | 3 | 4)
            : undefined,
        options: getOptionsForPatient(options) as {
          label: string
          value: string
        }[],
        type: 'button',
      }
    }
    case 'text':
    case 'number': {
      if (widget === 'height') {
        return {
          ...formField,
          type: 'height',
        }
      }

      if (widget === 'weight') {
        return {
          ...formField,
          placeholder: field.placeholder,
          type: 'weight',
        }
      }

      if (widget === 'date') {
        return {
          ...formField,
          placeholder: field.placeholder,
          type: 'date',
        }
      }

      if (widget === 'cholesterol') {
        return {
          ...formField,
          max: field.max,
          min: field.min,
          placeholder: field.placeholder,
          type: 'cholesterol',
        }
      }

      if (widget === 'glucose') {
        return {
          ...formField,
          max: field.max,
          min: field.min,
          placeholder: field.placeholder,
          type: 'glucose',
        }
      }

      if (widget === 'triglycerides') {
        return {
          ...formField,
          max: field.max,
          min: field.min,
          placeholder: field.placeholder,
          type: 'triglycerides',
        }
      }

      if (field.type === 'number') {
        return {
          ...formField,
          endAdornment: field.ui?.unitOfMeasure ?? field.labelEnd,
          max: field.max,
          min: field.min,
          placeholder: field.placeholder,
          startAdornment: field.labelStart,
          type: 'number',
        }
      }

      return {
        ...formField,
        endAdornment: field.ui?.unitOfMeasure ?? field.labelEnd,
        placeholder: field.placeholder,
        startAdornment: field.labelStart,
        type: 'text',
      }
    }
    case 'object':
    case 'array': {
      // TODO should/how do we support these?
    }
  }
  throw new Error(
    `Unsupported field type "${field.type}' for field "${field.name}"`,
  )
}

export function getOptionsForPatient(
  options: SelectOption[],
): SelectOption[] {
  return options
    .filter(
      ({ displayOnlyFor }) =>
        !displayOnlyFor?.length || displayOnlyFor.includes('patient'),
    )
    .map(({ labelForPatient, ...option }) => ({
      ...option,
      label: labelForPatient || option.label,
    }))
}

export function getFormPageFromRuleField(
  pageId: string,
  field: RuleField,
  sectionLabel: string,
): PageFormData {
  return {
    fields: [getFormFieldFromRuleField(field)],
    id: pageId,
    overrideHeaderTitle: sectionLabel,
    type: 'form',
  }
}

// Map (rule engine) rules to connection conditions (story navigation connections).
export function mapRulesToConnectionConditions(args: {
  rules: Rules
  parentPath?: string
}): StoryConnectionRule['conditions'] {
  const { rules, parentPath = '' } = args
  const isLogical = ['$and', '$or'].includes(rules.operator)
  const isRelational = !isLogical

  if (isLogical && rules.items) {
    const conditions = rules.items
      .map((rules) =>
        rules
          ? mapRulesToConnectionConditions({ parentPath, rules })
          : null,
      )
      .filter(Boolean)

    return rules.operator === '$and'
      ? { all: conditions }
      : { any: conditions }
  }
  if (isRelational && rules.field) {
    const fieldName = [parentPath, rules.field.replace(/^\$\./, '')]
      .filter(Boolean)
      .join('.')
      .replace(/^\$\./, '')
    const data = parentPath
      ? set({}, parentPath, rules.data)
      : rules.data

    return {
      all: [
        {
          // Conditions are always scoped to questionnaire responses.
          fact: 'questionnaireResponses',
          operator: mapRuleOperator(rules.operator),
          path: `$.${fieldName}`,
          value: get(data, fieldName),
        },
      ],
    }
  }

  return { all: [] }
}

export function mapRuleOperator(operator: Operator) {
  switch (operator) {
    case '$and':
      return 'and'
    case '$inBetween':
      return 'inBetween'
    case '$contains':
      return 'contains'
    case '$containsAnyOf': {
      console.error('Unsupported operator', '$containsAnyOf')
      return ''
    }
    case '$containsAll': {
      console.error('Unsupported operator', '$containsAll')
      return ''
    }
    case '$eq':
      return 'equal'
    case '$exists':
      return 'exists'
    case '$gt':
      return 'greaterThan'
    case '$gte':
      return 'greaterThanInclusive'
    case '$includes':
      return 'includes'
    case '$lt':
      return 'lessThan'
    case '$lte':
      return 'lessThanInclusive'
    case '$notContains':
      return 'doesNotContain'
    case '$notContainsAll': {
      console.error('Unsupported operator', '$notContainsAll')
      return ''
    }
    case '$notEq':
      return 'notEqual'
    case '$notIncludes':
      return 'notIncludes'
    case '$or':
      return 'or'
  }

  // Following operators are not supported:
  // in, notIn, notExists, emptyArray, notEmptyArray
  console.error(`Unsupported operator: ${operator}`)
  return ''
}

/**
 * Extract rule fields from form schema sections.
 * @param sections Form schema sections
 * @returns Rule fields
 */
export function getRuleFieldsFromSections(sections: Section[]) {
  return sections.reduce<{ field: RuleField; section: Section }[]>(
    (acc, section) => {
      section.fields?.forEach((field) => {
        // Prepend form and section name to field names to define data scope.
        const name = [section.name, field.name]
          .filter(Boolean)
          .join('.')
        acc.push({ field: { ...field, name }, section })
      })
      return acc
    },
    [],
  )
}

/**
 * Get the field path for a data field.
 * @param parentPath Parent path of the field path.
 * @param fieldName Target field name.
 * @returns Data field path
 */
export function getFieldPath(parentPath = '', fieldName = '') {
  return [trim(parentPath, '.'), trim(fieldName, '.')]
    .filter(Boolean)
    .join('.')
}

/**
 * Determines if a field name is a relative path (starting with `$.`)
 * @param fieldName - Field name to check.
 * @returns boolean result for check.
 */
function checkRelativePath(fieldName: string) {
  return /^\$\./.test(fieldName)
}

/**
 * Get the field path for a field name. If relative path, it will be converted to absolute path.
 * @param parentPath - Parent path of the field path.
 * @param fieldName - Target field name.
 * @returns Field path.
 * @example
 * getAbsoluteFieldPath('familyCancerHistory.0.diagnosis.0', '$.ageAtDiagnosis')
 * // returns 'familyCancerHistory.0.diagnosis.0.ageAtDiagnosis'
 */
function getAbsoluteFieldPath(parentPath: string, fieldName: string) {
  return checkRelativePath(fieldName)
    ? getFieldPath(parentPath, trim(fieldName, '$.'))
    : fieldName
}

/**
 * Convert form schema sections to connected form pages.
 * @param args.sections Form schema sections
 * @param args.getPageId Function to generate page ID
 * @param args.returnToPageId Page ID to return to
 * @returns Form pages
 */
export function getFormPagesFromSections(args: {
  sections: Section[]
  getPageId: (fieldName: string) => string
  returnToPageId: string
}) {
  const { sections = [], getPageId, returnToPageId } = args
  const ruleFieldResults = getRuleFieldsFromSections(sections)
  const dependentFieldItems = ruleFieldResults.reduce<
    RuleField['dependentFields']
  >((acc, { field }) => {
    const parentPath = field.name.split('.').slice(0, -1).join('.')
    field.dependentFields?.forEach((item) => {
      const itemFieldPath = getAbsoluteFieldPath(
        parentPath,
        item.fieldName,
      )
      const existingItem = acc.find(
        ({ fieldName }) => fieldName === itemFieldPath,
      )
      if (
        !ruleFieldResults.some(
          ({ field: { name } }) => name === itemFieldPath,
        )
      ) {
        console.error(
          `The "${field.name}" field defines an invalid dependent field ("${itemFieldPath}") for "dependentFields".`,
        )
        return
      }
      if (!existingItem) {
        acc.push({ ...item, fieldName: itemFieldPath })
      }
    })
    return acc
  }, [])
  const dependentRuleFieldNames = dependentFieldItems.map(
    ({ fieldName }) => fieldName,
  )
  const formPages = ruleFieldResults.reduce<PageFormData[]>(
    (acc, { field, section }, index) => {
      const page = getFormPageFromRuleField(
        getPageId(field.name),
        field,
        section.label,
      )
      const nextField = ruleFieldResults
        .slice(index + 1)
        // Exclude dependent fields from the next field.
        .find(
          ({ field: { name } }) =>
            !dependentRuleFieldNames.includes(name),
        )?.field
      const nextPageId = nextField?.name
        ? getPageId(nextField?.name)
        : null

      // Add default page connection.
      page.defaultConnectionPageId = nextPageId ?? returnToPageId

      // Add conditional page connections.
      if (field.dependentFields?.length) {
        page.connectionRules = field.dependentFields.map(
          ({ fieldName, displayRules }) => {
            const itemFieldPath = getAbsoluteFieldPath(
              section.name,
              fieldName,
            )
            return {
              conditions: mapRulesToConnectionConditions({
                parentPath: section.name,
                rules: displayRules,
              }),
              event: {
                params: { pageId: getPageId(itemFieldPath) },
                type: 'StoryNavigation',
              },
            }
          },
        )
      }

      acc.push(page)
      return acc
    },
    [],
  )
  return formPages
}
