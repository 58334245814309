import React, { useContext } from 'react'
import {
  Box,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import {
  BasePageProps,
  PageData,
} from 'components/StoryPlayer/StoryPage'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import {
  MotionElement,
  MotionTrail,
} from 'components/MotionComponents'
import useInterpolate from 'hooks/useInterpolate'
import AnimationBlock, {
  AnimationBlockProps,
} from 'components/blocks/AnimationBlock'
import ImageBlock, {
  ImageBlockProps,
} from 'components/blocks/ImageBlock'
import ObjectBlock, {
  ObjectBlockProps,
} from 'components/blocks/ObjectBlock'
import SvgBlock, { SvgBlockProps } from 'components/blocks/SvgBlock'
import seedrandom from 'seedrandom'
import PageFooter from 'components/StoryPlayer/PageFooter'
import MarkdownContent from 'components/MarkdownContent'
import ActionButtonBlock from 'components/blocks/ActionButtonBlock'
import { useTranslation } from 'react-i18next'

const HeroBackgroundImgSrc = [
  '/assets/page-hero-bg-01.svg',
  '/assets/page-hero-bg-02.svg',
  '/assets/page-hero-bg-03.svg',
]

export interface PageStoryStartData extends PageData {
  type: 'story-start'
  hero?:
    | {
        type: 'account-logo'
        enableNestLogo?: boolean
        sx?: ImageBlockProps['sx']
        width?: number | string
        height?: number | string
      }
    | ({ type: 'image' } & ImageBlockProps)
    | ({ type: 'svg' } & SvgBlockProps)
    | ({ type: 'object' } & ObjectBlockProps)
    | ({ type: 'animation' } & AnimationBlockProps)
  heroBackgroundImgSrc?: string
  title: string
  subtitle?: string
  description: string
  averageTimeSpent?: string
  enableHipaaBadge?: boolean
}

export type PageStoryStartProps = Pick<
  BasePageProps,
  | 'submitLabel'
  | 'disableMotion'
  | 'enableActionIconButton'
  | 'onNext'
> & {
  id: string
  hero?: PageStoryStartData['hero']
  heroBackgroundImgSrc?: string
  title: string
  subtitle?: string
  description: string
  averageTimeSpent?: string
  enableHipaaBadge?: boolean
}

export default function PageStoryStart(
  props: PageStoryStartProps,
): JSX.Element {
  const { t } = useTranslation()
  const {
    hero,
    title,
    subtitle,
    description,
    submitLabel,
    averageTimeSpent,
    disableMotion,
    enableHipaaBadge,
    enableActionIconButton,
  } = props
  const hasAccountLogo = hero?.type === 'account-logo'
  const enableNestLogo = hasAccountLogo && hero?.enableNestLogo
  const { palette } = useTheme()
  const interpolate = useInterpolate(StoryPlayerContext)
  const backgroundSeedIndex = Math.floor(
    seedrandom(title)() * HeroBackgroundImgSrc.length,
  )
  const backgroundColor = palette.background.default
  const { patient } = useContext(StoryPlayerContext)
  // Use configured hero background or a random one.
  const heroBackgroundImgSrc =
    props.heroBackgroundImgSrc ??
    HeroBackgroundImgSrc[backgroundSeedIndex]

  return (
    <>
      <Stack
        sx={{
          alignItems: 'stretch',
          containerType: 'size',
          flexGrow: 1,
          justifyContent: 'flex-start',
        }}
      >
        <MotionElement
          key={`hero.${props.id}`}
          className="hero"
          sx={{
            '& .ImageBlock, .AnimationBlock, .SvgBlock, .ObjectBlock':
              // Override styles to support full bleed hero.
              {
                '&.AnimationBlock, &.SvgBlock': {
                  pt: 4,
                },
                borderRadius: 0,
                maxHeight: 1,
                maxWidth: 1,
                my: 0,
                width: hero?.width ?? 1,
              },
            '& > *:not(.nestLogo)': {
              position: 'relative',
            },
            '&:before': {
              background: `linear-gradient(180deg, ${alpha(
                backgroundColor,
                0,
              )} 0%, ${alpha(backgroundColor, 1)} 100%)`,
              bottom: 0,
              content: '""',
              display: 'block',
              height: '33%',
              left: 0,
              position: 'absolute',
              right: 0,
              width: 1,
            },
            alignItems: 'center',
            backgroundImage: `url(${heroBackgroundImgSrc})`,
            backgroundPosition: '100%',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            height: '40cqh',
            justifyContent: 'center',
            minHeight: 240,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {hasAccountLogo && (
            <>
              {enableNestLogo && (
                <Box
                  className="nestLogo"
                  component="img"
                  src="/img/nest-logo.svg"
                  alt="Nest logo"
                  sx={{
                    display: 'block',
                    left: 0,
                    m: 3,
                    maxWidth: '25%',
                    objectFit: 'contain',
                    position: 'absolute',
                    top: 0,
                    width: 'auto',
                  }}
                />
              )}
              <Box
                component="img"
                src={patient?.account?.logoSrc}
                alt={patient?.account?.name}
                width={hero?.width}
                height={hero?.height}
                sx={{
                  display: 'block',
                  maxHeight: '100%',
                  maxWidth: '90%',
                  mx: 'auto',
                  objectFit: 'contain',
                  objectPosition: 'center',
                  p: 6,
                  ...hero?.sx,
                }}
              />
            </>
          )}
          {!enableNestLogo && (
            <>
              {hero?.type === 'image' && (
                <ImageBlock
                  disableMotion={disableMotion}
                  {...hero}
                  sx={{
                    height: 1,
                    ...hero.sx,
                  }}
                />
              )}
              {hero?.type === 'svg' && (
                <SvgBlock disableMotion={disableMotion} {...hero} />
              )}
              {hero?.type === 'object' && (
                <ObjectBlock
                  disableMotion={disableMotion}
                  {...hero}
                />
              )}
              {hero?.type === 'animation' && (
                <AnimationBlock
                  disableMotion={disableMotion}
                  {...hero}
                />
              )}
            </>
          )}
        </MotionElement>
        <Stack sx={{ gap: 2, pb: 4, pt: 3, px: 3 }}>
          <MotionTrail
            id="story-start"
            items={[
              <Stack key="story-start.heading">
                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                  {interpolate(title)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    mt: -0.5,
                  }}
                >
                  {interpolate(subtitle)}
                </Typography>
              </Stack>,
              <Typography
                key="story-start.description"
                component="div"
                variant="body1"
                sx={{ mb: 1, mt: -0.5 }}
              >
                <MarkdownContent value={interpolate(description)} />
              </Typography>,
              averageTimeSpent && (
                <Stack
                  key="story-start.averageTimeSpent"
                  direction="row"
                  sx={{ alignItems: 'center', gap: 1 }}
                >
                  <Stack sx={{ px: 1 }}>
                    <AccessTimeOutlinedIcon />
                  </Stack>
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: 'text.secondary',
                        lineHeight: 1,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('pageForm.averageTimeSpent')}
                    </Typography>
                    <Typography variant="body1">
                      {interpolate(averageTimeSpent)}
                    </Typography>
                  </Stack>
                </Stack>
              ),
              enableHipaaBadge && (
                <Box
                  key="story-start.hipaaBadge"
                  component="img"
                  src="/img/hipaa-badge.svg"
                  alt="HIPAA Compliant badge"
                  sx={{
                    display: 'block',
                    height: 24,
                    mx: 'auto',
                    width: 79,
                  }}
                />
              ),
            ].filter(Boolean)}
          />
        </Stack>
      </Stack>
      <PageFooter>
        <ActionButtonBlock
          variant={enableActionIconButton ? 'icon' : 'label'}
          label={submitLabel}
          onClick={props.onNext}
        />
      </PageFooter>
    </>
  )
}
