import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import useDialog from 'hooks/useDialog'
import {
  DialogCloseButton,
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import { Box, DialogContent } from '@mui/material'
import { useAuth } from '@frontegg/react'
import LoadingBackdrop from './LoadingBackdrop'
import { AppContext } from 'components/AppContext'
import MarkdownContent from 'components/MarkdownContent'
import useHashParams from 'hooks/useHashParams'

type DocumentParams = {
  id: string
}

export const getDocumentParamsFromPath = (
  url = '',
): DocumentParams => {
  const matches = url?.match?.(/#\/documents\/?([\w-]+)/)
  return {
    id: matches?.[1],
  }
}

function useDocumentHashParams(options: {
  disabled?: boolean
}): DocumentParams {
  const { disabled } = options
  const [params, setParams] = useState<DocumentParams>({
    id: null,
  })
  const onHashChange = useCallback(() => {
    setParams(getDocumentParamsFromPath(window.location.hash))
  }, [])

  useHashParams({ disabled, onHashChange })
  return params
}

export default function DocumentDialog() {
  const { patient, todosLoading } = useContext(AppContext)
  const [dialogProps, { setOpen: setDialogOpen }] =
    useDialog('documentDialog')
  const { isAuthenticated } = useAuth()
  const params = useDocumentHashParams({
    // Require authenticated user to enable hash params.
    disabled: !isAuthenticated,
  })
  const documents = [
    // Add web-based document resources.
    ...(patient?.clinicalDocuments ?? []),
  ]
  const document = documents.find(({ id }) => id === params.id)

  useEffect(() => {
    // Only open dialog if patientTodoId exists.
    setDialogOpen(!!document)
  }, [document, setDialogOpen])

  return (
    <>
      <LoadingBackdrop open={todosLoading} />
      <EnhancedDialog
        {...dialogProps}
        fullWidth
        maxWidth="md"
        scroll="paper"
        disableCloseOnBackdropClick
      >
        <EnhancedDialogTitle
          sx={{
            p: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              bgcolor: 'background.paper',
              borderRadius: 5,
              mx: 2,
              my: 1.5,
            }}
          >
            <DialogCloseButton
              sx={{
                '&::before': {
                  bgcolor: 'action.hover',
                  borderRadius: 5,
                  content: '""',
                  display: 'block',
                  height: 1,
                  position: 'absolute',
                  width: 1,
                },
              }}
              onClick={() => {
                dialogProps.onClose()
                // Clear hash and related state after dialog close.
                setTimeout(() => {
                  window.location.hash = ''
                }, 300)
              }}
            />
          </Box>
        </EnhancedDialogTitle>
        <DialogContent>
          <MarkdownContent sx={{ py: 2 }} value={document?.content} />
        </DialogContent>
      </EnhancedDialog>
    </>
  )
}
