import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardContent,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'
import {
  BasePageProps,
  PageData,
  StoryPageProps,
} from 'components/StoryPlayer/StoryPage'
import {
  MotionElement,
  MotionTrail,
} from 'components/MotionComponents'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import useInterpolate from 'hooks/useInterpolate'
import PageFooter from 'components/StoryPlayer/PageFooter'
import ButtonBlock from 'components/blocks/ButtonBlock'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { cloneDeep, get, isArray, isUndefined } from 'lodash'
import {
  flattenQuestionnaireResponse,
  formatFormFields,
  getTemplatePages,
  useTemplatePages,
} from 'components/StoryPlayerUtil'
import { QuestionnaireResponseItemInput } from '__generated__/graphql'
import { FormField } from 'components/StoryPlayer/pages/PageForm'
import MarkdownContent from 'components/MarkdownContent'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import useDialog from 'hooks/useDialog'
import MenuIconButton from 'components/MenuIconButton'
import { useTranslation } from 'react-i18next'

const ItemIconComponent = {
  'health-condition': SubjectRoundedIcon,
  person: PersonRoundedIcon,
}

export interface PageTemplateOverviewData extends PageData {
  type: 'template-overview'
  listTitle?: string
  addLabel?: string
  itemDataKey: string
  itemIconType?: keyof typeof ItemIconComponent
  itemPrimaryLabel: string
  itemSecondaryLabel?: string
  itemModalTitle?: string
  itemModalContent?: string
  emptyLabel?: string
  submitLabelOnEmpty?: never
  enableClose?: never
}

export type PageTemplateOverviewProps = Omit<
  BasePageProps,
  'onClose' | 'submitLabelOnEmpty' | 'enableClose'
> & {
  id: string
  listTitle?: string
  addLabel?: string
  itemDataKey: string
  itemIconType?: PageTemplateOverviewData['itemIconType']
  itemPrimaryLabel: string
  itemSecondaryLabel?: string
  itemModalTitle?: string
  itemModalContent?: string
  emptyLabel?: string
  gotoPage: StoryPageProps['gotoPage']
  onSubmit: StoryPageProps['onSubmit']
}

export default function PageTemplateOverview(
  props: PageTemplateOverviewProps,
): JSX.Element {
  const { t } = useTranslation()
  const {
    disableMotion,
    sx,
    listTitle,
    addLabel,
    itemIconType,
    itemDataKey,
    itemPrimaryLabel,
    itemSecondaryLabel,
    emptyLabel,
    gotoPage,
    onSubmit,
    submitLabel,
    itemModalTitle,
    itemModalContent,
    id,
  } = props
  const {
    questionnaireResponses,
    questionnaireResponseItems,
    setQuestionnaireResponseItems,
    storyProgress,
    storyId,
    templatePages,
  } = useContext(StoryPlayerContext)
  const ItemIcon = itemIconType
    ? ItemIconComponent[itemIconType]
    : null
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const { createTemplatedPages, getTemplatedPageId } =
    useTemplatePages()
  const [dialogProps, { setOpen: setDialogOpen }] =
    useDialog('overviewDialog')
  const interpolate = useInterpolate(StoryPlayerContext)
  const indexes = (id.match(/\.(\d+)/g) ?? []).map((i) =>
    parseInt(i.match(/\d+/)[0]),
  )
  const dataKey = (
    itemDataKey.match(/\[\]/g) ?? ([] as string[])
  ).reduce(
    (acc, _, index) => acc.replace('[]', `[${indexes[index]}]`),
    itemDataKey as string,
  )
  const interpolateItemIndex = useCallback(
    (label: string, index: number) =>
      // Replace `itemIndex` to support handlebar helpers.
      // e.g. getAnswerLabel
      label?.replaceAll('{{itemIndex}}', `${index}`) ?? '',
    [],
  )
  const additionalData = useMemo(
    () => ({
      itemIndex: selectedIndex,
      itemNumber: selectedIndex + 1,
    }),
    [selectedIndex],
  )
  const value = get(questionnaireResponses, dataKey)
  const items = isArray(value) ? (value as unknown[]) : []
  const gotoEdit = useCallback(
    (index: number) => {
      const pageId = getTemplatedPageId(dataKey, index)
      gotoPage(pageId)
      setDialogOpen(false)
    },
    [dataKey, getTemplatedPageId, gotoPage, setDialogOpen],
  )
  const removeByIndex = useCallback(
    async (index: number) => {
      const rootDataKey = itemDataKey.match(/^\w+/)[0]
      const updatedItems = cloneDeep(questionnaireResponseItems).map(
        (
          item: QuestionnaireResponseItemInput & {
            answer: unknown[]
          },
        ) => {
          // Match questionnaire response item by root data key.
          if (item.name === rootDataKey) {
            // Remove item from answers.
            get(
              // Create temp object to query and modify by dataKey.
              { [rootDataKey]: item.answer },
              dataKey,
            ).splice(index, 1)
          }
          return item
        },
      )
      setQuestionnaireResponseItems(updatedItems)
      setDialogOpen(false)

      const fields: FormField[] = [
        {
          fields:
            // Get existing fields data.
            updatedItems.find((item) => item.name === rootDataKey)
              ?.metadata?.fields ?? [],
          multiple: true,
          name: rootDataKey,
          type: 'object',
        },
      ]

      // Persist questionnaire response changes.
      await onSubmit(
        flattenQuestionnaireResponse(updatedItems),
        formatFormFields(fields, updatedItems),
      )

      // Remove related page history from story progress.
      const history = storyProgress.getHistory(storyId)
      const removePageIds = getTemplatePages(
        itemDataKey,
        templatePages,
      ).map((page) => `${page.id}.${items.length - 1}`)
      const updatedHistory = history.filter(
        (pageId) => !removePageIds.includes(pageId),
      )

      storyProgress.setHistory(storyId, updatedHistory)
    },
    [
      dataKey,
      itemDataKey,
      items.length,
      onSubmit,
      questionnaireResponseItems,
      setDialogOpen,
      setQuestionnaireResponseItems,
      storyId,
      storyProgress,
      templatePages,
    ],
  )

  return (
    <>
      <MotionElement
        disableMotion={disableMotion}
        sx={{
          alignItems: 'stretch',
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 1,
          justifyContent: 'flex-start',
          pb: 0,
          pt: 14,
          px: 3,
          ...sx,
        }}
      >
        {listTitle && (
          <Typography variant="subtitle2">
            {interpolate(listTitle)}
          </Typography>
        )}
        {!isUndefined(value) && !isArray(value) && (
          <Alert severity="error">
            {t('pageForm.invalidQuestionnaireResponse', {
              value: itemDataKey,
            })}
          </Alert>
        )}
        {!items.length && (
          <Card
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              minHeight: 70,
              p: 3,
              textAlign: 'center',
            }}
          >
            <MarkdownContent
              value={interpolate(
                emptyLabel ?? t('pageForm.noItemsAdded'),
              )}
            />
          </Card>
        )}
        <MotionTrail
          id="templateOverviewItems"
          items={items.map((item, itemIndex) => {
            const additionalData = {
              itemIndex,
              itemNumber: itemIndex + 1,
            }

            return (
              <Card
                key={`templateOverview.${JSON.stringify(
                  item,
                )}.${itemIndex}`}
              >
                <CardActionArea
                  disableRipple
                  disableTouchRipple
                  sx={{
                    '&.Mui-focusVisible:before': {
                      borderRadius: 1,
                      boxShadow: ({ palette }) =>
                        `0 0 0 2px ${palette.primary.main} inset`,
                      content: '""',
                      display: 'block',
                      height: 1,
                      position: 'absolute',
                      width: 1,
                    },
                  }}
                  onClick={() => {
                    setSelectedIndex(itemIndex)
                    if (itemModalTitle && itemModalContent) {
                      setDialogOpen(true)
                    } else {
                      document
                        .getElementById(`menuButton.${itemIndex}`)
                        .click()
                    }
                  }}
                >
                  <CardContent
                    sx={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 1,
                      justifyContent: 'space-between',
                    }}
                  >
                    {ItemIcon && (
                      <ItemIcon
                        sx={{
                          color: ({ palette }) =>
                            palette.text.secondary,
                        }}
                      />
                    )}
                    <Stack
                      sx={{
                        alignItems: 'flex-start',
                        flexGrow: 1,
                        justifyContent: 'center',
                        minHeight: 40,
                        textAlign: 'left',
                        width: 1,
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.primary"
                        component="div"
                      >
                        <MarkdownContent
                          value={interpolate(
                            interpolateItemIndex(
                              itemPrimaryLabel,
                              itemIndex,
                            ),
                            additionalData,
                          )}
                        />
                      </Typography>
                      {itemSecondaryLabel && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="div"
                        >
                          <MarkdownContent
                            value={interpolate(
                              interpolateItemIndex(
                                itemSecondaryLabel,
                                itemIndex,
                              ),
                              additionalData,
                            )}
                          />
                        </Typography>
                      )}
                    </Stack>
                    {itemModalTitle && itemModalContent ? (
                      <EditRoundedIcon
                        color="action"
                        fontSize="small"
                      />
                    ) : (
                      <MenuIconButton
                        IconComponent={EditRoundedIcon}
                        IconProps={{
                          color: 'action',
                          fontSize: 'small',
                        }}
                        IconButtonProps={{
                          // Avoid button nested in button warning.
                          LinkComponent: 'span',
                          disableRipple: true,
                          disableTouchRipple: true,
                          href: '#',
                          id: `menuButton.${itemIndex}`,
                          onClick: (e) => e.preventDefault(),
                          sx: {
                            '&:hover': {
                              bgcolor: 'transparent',
                            },
                            my: -0.5,
                          },
                        }}
                        menuItems={[
                          {
                            label: t('pageForm.edit'),
                            onClick: () => gotoEdit(itemIndex),
                          },
                          {
                            label: t('pageForm.remove'),
                            onClick: () => removeByIndex(itemIndex),
                          },
                        ]}
                      />
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            )
          })}
        />
      </MotionElement>
      <EnhancedDialog {...dialogProps} maxWidth="xs">
        <EnhancedDialogTitle onClose={dialogProps.onClose}>
          <Typography variant="h5" component="div">
            <MarkdownContent
              value={interpolate(
                interpolateItemIndex(itemModalTitle, selectedIndex),
                additionalData,
              )}
            />
          </Typography>
        </EnhancedDialogTitle>
        {/* <Divider /> */}
        <DialogContent
          sx={{
            '& th': {
              textAlign: 'start',
            },
          }}
        >
          <MarkdownContent
            value={interpolate(
              interpolateItemIndex(itemModalContent, selectedIndex),
              additionalData,
            )}
          />
        </DialogContent>
        {/* <Divider /> */}
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => gotoEdit(selectedIndex)}
          >
            {t('pageForm.edit')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => removeByIndex(selectedIndex)}
          >
            {t('pageForm.remove')}
          </Button>
        </DialogActions>
      </EnhancedDialog>
      <PageFooter>
        <ButtonBlock
          type="button"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => {
            const nextPageId = createTemplatedPages(
              dataKey,
              items.length,
            )
            gotoPage(nextPageId)
          }}
        >
          {interpolate(addLabel ?? t('pageForm.addAnother'))}
        </ButtonBlock>
        <ButtonBlock
          type="button"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={props.onNext}
        >
          {interpolate(submitLabel ?? t('pageForm.next'))}
        </ButtonBlock>
      </PageFooter>
    </>
  )
}
