import React, { Ref, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Checkbox,
  ListSubheader,
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  OutlinedInputProps,
  Skeleton,
} from '@mui/material'
import { SelectOption } from 'types/Global'
import { isArray, isFunction, omit } from 'lodash'
import { useTranslation } from 'react-i18next'

const displaySx = {
  '& .MuiFormHelperText-root': {
    m: 0,
  },
  '& .MuiInputLabel-root': {
    mb: 0,
  },
  '& .MuiOutlinedInput-input': {
    p: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: 'inherit',
    p: 0,
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
}

const readOnlySx = {
  '& .MuiInput-root .MuiInputBase-input.MuiSelect-select': {
    pr: 0,
  },
  '& .MuiInputBase-root.MuiInput-underline:before': {
    borderColor: 'action.disabled',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'text.secondary',
  },
  '& .MuiOutlinedInput-input': {
    cursor: 'default',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      borderColor: 'action.disabled',
      borderWidth: 1,
    },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'action.disabled',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
}

type SelectHeaderOption = {
  title: string
  label?: never
  value?: never
  disabled?: never
}
export type TextFieldSelectOption =
  | (SelectOption & {
      title?: never
    })
  | SelectHeaderOption

export type TextFieldProps =
  // Text field type.
  | (MuiTextFieldProps & {
      autoWidth?: boolean
      asDisplay?: boolean
      loading?: boolean
      select?: false
      options?: never
    })
  // Select field type.
  | (MuiTextFieldProps & {
      autoWidth?: boolean
      asDisplay?: boolean
      loading?: boolean
      select: boolean
      options:
        | TextFieldSelectOption[]
        | (() => Promise<TextFieldSelectOption[]>)
    })
const TextField = React.forwardRef(function TextField(
  props: TextFieldProps,
  ref: Ref<HTMLDivElement>,
) {
  const { asDisplay, loading, autoWidth, ...textFieldProps } = props
  const { t } = useTranslation()
  const [options, setOptions] = useState<TextFieldSelectOption[]>(
    isArray(props.options)
      ? props.options
      : [
          // Use default loading option when loading async options.
          {
            label: t('textField.loadingLabel'),
            value: (props.value as string) ?? '',
          },
        ],
  )
  const readOnly = asDisplay || !!props.InputProps?.readOnly
  const SelectProps = useMemo(() => {
    if (!props.select) return null
    return {
      ...props.SelectProps,
      displayEmpty: true,
      renderValue:
        !props.value || !(props.value as unknown[])?.length
          ? () => (
              <Box
                sx={{
                  opacity: 0.5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {props.placeholder}
              </Box>
            )
          : props.SelectProps?.renderValue,
    }
  }, [
    props.SelectProps,
    props.select,
    props.value,
    props.placeholder,
  ])
  const hasMenuItemGroups = (options ?? []).some(
    (option) => option.title,
  )

  useEffect(() => {
    let mounted = true
    if (isArray(props.options)) {
      setOptions(props.options)
    } else if (isFunction(props.options)) {
      props
        .options()
        .then((result) => {
          if (mounted) {
            setOptions(result)
          }
        })
        .catch((e) => {
          if (mounted) {
            setOptions([])
            console.error(e)
          }
        })
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options])

  if (loading) {
    return (
      <Box sx={{ ...props.sx, width: 1 }}>
        {props.label && <Skeleton width="33%" height="1em" />}
        {asDisplay ? (
          <Skeleton height="1.7em" />
        ) : (
          <Box
            sx={{
              border: ({ palette }) => `solid 1px ${palette.divider}`,
              borderRadius: 1,
              lineHeight: 24,
              mt: 1,
              padding: '8.5px 14px',
            }}
          >
            <Skeleton width="66%" height={'1.28em'} />
          </Box>
        )}
      </Box>
    )
  }

  return (
    <>
      <MuiTextField
        ref={ref}
        {...omit(textFieldProps, ['options'])}
        variant={props.variant}
        fullWidth={props.fullWidth}
        SelectProps={SelectProps}
        size={props.size ?? 'small'}
        InputProps={
          {
            ...props.InputProps,
            // Disabled notch only for outlined input (default variant).
            notched: [undefined, 'outlined'].includes(props.variant)
              ? false
              : undefined,
            readOnly,
          } as OutlinedInputProps
        }
        InputLabelProps={{
          ...props.InputLabelProps,
          disableAnimation: true,
          shrink: true,
        }}
        required={!asDisplay && props.required}
        sx={[
          {
            '& .MuiCheckbox-root': {
              display: 'none',
            },
            '& .MuiFormHelperText-root': {
              ml: 0,
            },
            '& .MuiInput-root.Mui-disabled:before': {
              borderBottomColor: 'action.disabled',
              zIndex: 0,
            },
            '& .MuiInputBase-input': {
              overflow: props.multiline ? 'auto' : 'hidden',
              textOverflow: 'ellipsis',
            },
            '& .MuiInputLabel-root': {
              zIndex: 0,
            },
            '& .MuiInputLabel-root + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline > legend > *':
              {
                // Remove notch in the outlined input.
                display: 'none',
              },
          },
          autoWidth && {
            // Delegate width to `before` element.
            '& .MuiInput-root': {
              bottom: '-0.089em',
              position: 'absolute',
            },
            '&::before': {
              content: `"${props.value ?? ''}"`,
            },
          },
          props.sx as unknown,
          asDisplay && displaySx,
          readOnly && readOnlySx,
        ]}
      >
        {props.select &&
          options?.map((option, index) =>
            option.title ? (
              <ListSubheader key={`title.${props.name}.${index}`}>
                {option.title}
              </ListSubheader>
            ) : (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                sx={{
                  px: hasMenuItemGroups ? 4 : undefined,
                }}
              >
                {props.SelectProps?.multiple && (
                  <Checkbox
                    checked={(
                      (props.value ?? []) as (string | number)[]
                    ).includes(option.value)}
                    size="small"
                    disableRipple
                    sx={{
                      ml: -1,
                      py: 0.5,
                    }}
                  />
                )}
                {option.label}
              </MenuItem>
            ),
          )}
      </MuiTextField>
    </>
  )
})

export default TextField
