import {
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import React from 'react'
import { useTranslation } from 'react-i18next'
import UserStore from 'stores/UserStore'

export type LanguageDialogProps = DialogProps & {
  onClose: () => void
}

export default function LanguageDialog(props: LanguageDialogProps) {
  const { t, i18n } = useTranslation()
  const languages = (i18n.options.supportedLngs as string[]).filter(
    (v) => v !== 'cimode', // we are filtering out cimode as it is a special i18next language for e2e testing
  )
  return (
    <EnhancedDialog
      {...props}
      maxWidth="xs"
      fullWidth
      sx={{
        '& .MuiFormControlLabel-asterisk': {
          // Hide asterisk and use inline asterisk instead.
          display: 'none',
        },
        '& .MuiFormControlLabel-label': {
          '& a': {
            color: 'primary.main',
          },
          typography: 'body2',
        },
      }}
    >
      <EnhancedDialogTitle
        onClose={() => {
          props.onClose()
        }}
      >
        {t('languageDialog.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            onChange={(e) => {
              const value = e.target.value
              i18n.changeLanguage(value)
              UserStore.set('user.preferredLanguage', value)
            }}
            value={i18n.language}
          >
            {languages.map((lang) => (
              <FormControlLabel
                key={lang}
                value={lang}
                control={<Radio />}
                label={t(`languageDialog.${lang}`)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </EnhancedDialog>
  )
}
