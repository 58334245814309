import { ContentAccountData } from '@shared/constants'

const contentAccounts: ContentAccountData[] = [
  {
    categories: [],
    id: 'patient-questionnaires',
    name: 'Patient Questionnaires',
    summary: '',
    themeColor: '#E75732',
    username: 'Questionnaires',
  },
  {
    avatar: {
      src: '/img/nest-symbol.svg',
    },
    categories: [],
    id: 'nest',
    name: 'About Nest',
    summary: '',
    themeColor: '#E75732',
    username: 'Nest',
  },
  {
    avatar: {
      src: '/img/avatar-sharing-information.svg',
    },
    categories: [],
    id: 'sharingInformation',
    name: 'Sharing Information',
    summary: '',
    themeColor: '#E75732',
    username: 'Sharing Information',
  },
  // {
  //   avatar: {
  //     src: '/img/avatar-family-planning.svg',
  //   },
  //   categories: [],
  //   id: 'familyPlanning',
  //   name: 'Family Planning',
  //   summary: '',
  //   themeColor: '#E75732',
  //   username: 'Family Planning',
  // },
  // {
  //   avatar: {
  //     src: '/img/avatar-family-planning.svg', // TODO: find a better avatar
  //   },
  //   categories: [],
  //   id: 'patientConsent',
  //   name: 'Patient Consent',
  //   summary: '',
  //   themeColor: '#E75732',
  //   username: 'Patient Consent',
  // },
  // {
  //   avatar: {
  //     src: '/img/avatar-screening-and-prevention.svg',
  //   },
  //   categories: [],
  //   id: 'screeningAndPrevention',
  //   name: 'Screening and Prevention',
  //   summary: '',
  //   themeColor: '#E75732',
  //   username: 'Screening and Prevention',
  // },
  // {
  //   avatar: {
  //     src: '/img/avatar-community.svg',
  //   },
  //   categories: [],
  //   id: 'community',
  //   name: 'About Community',
  //   summary:
  //     'Educational information and personal perspectives relevant to the hereditary cancer community.',
  //   themeColor: '#EA9610',
  //   username: 'Community',
  // },
  {
    avatar: {
      src: '/img/avatar-genetics-101.svg',
    },
    categories: [],
    id: 'genetics-101',
    name: 'Genetics 101',
    subtitle: 'Education Guide',
    summary:
      'Learn how individual genes or groups of genes are involved in health and disease. Understanding genetic factors and genetic disorders is important in learning more about promoting health and preventing disease.',
    themeColor: '#3388FF',
    username: 'Genetics 101',
  },
]

export default contentAccounts
