import React from 'react'
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseRounded'

export type EnhancedDialogProps = Omit<MuiDialogProps, 'onClose'> & {
  disableCloseOnBackdropClick?: boolean
  onClose?: (event?: React.SyntheticEvent<unknown, Event>) => void
}

export function EnhancedDialog(props: EnhancedDialogProps) {
  const { disableCloseOnBackdropClick, ...dialogProps } = props
  return (
    <MuiDialog
      {...dialogProps}
      sx={{
        '& .MuiDialog-paper': {
          '@media (max-width: 599px)': {
            maxWidth: `calc(100% - 16px)`,
            mx: 1,
            width: 1,
          },
        },
        ...dialogProps.sx,
      }}
      open={props.open}
      onClose={(event, reason) => {
        // Ignore close attempts depending on props.
        if (
          disableCloseOnBackdropClick &&
          reason === 'backdropClick'
        ) {
          return
        }
        props.onClose?.()
      }}
    />
  )
}

type EnhancedDialogTitleProps = DialogTitleProps & {
  onClose?: () => void
}

export function EnhancedDialogTitle(props: EnhancedDialogTitleProps) {
  const { children, onClose, ...dialogTitleProps } = props
  return (
    <DialogTitle {...dialogTitleProps}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={[
            { typography: 'h4' },
            {
              fontWeight: 500,
              minWidth: 0,
              width: 1,
            },
          ]}
        >
          {children}
        </Stack>
        {onClose && (
          <DialogCloseButton
            onClick={onClose}
            sx={{ mr: -1.5, my: -1 }}
          />
        )}
      </Stack>
    </DialogTitle>
  )
}

export function DialogCloseButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <CloseIcon />
    </IconButton>
  )
}
