import React, { WheelEvent, useEffect, useState } from 'react'
import { InputAdornment } from '@mui/material'
import {
  Field,
  FieldProps,
  FormValues,
} from 'components/StoryPlayer/pages/PageForm'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import StoryFieldLabel from 'components/StoryFieldLabel'
import HelperBlocks from './HelperBlocks'
import { Controller, UseFormReturn } from 'react-hook-form'
import { omit } from 'lodash'
import { isValidNumber, parseNumberString } from 'utils/FormUtil'
import { useTranslation } from 'react-i18next'

export interface NumberField extends Field {
  type: 'number'
  placeholder?: string
  helperText?: string
  min?: number
  max?: number
  startAdornment?: string
  endAdornment?: string
  autoFocus?: boolean
  defaultValue?: number
}

export interface StoryNumberFieldProps
  extends NumberField,
    FieldProps {
  disabled?: boolean
  formContext: UseFormReturn<FormValues>
}

export default function StoryNumberField(
  props: StoryNumberFieldProps,
) {
  const {
    fieldState,
    formContext,
    label,
    required,
    startAdornment,
    endAdornment,
    helperBlocks,
    disableRequiredAsterisk,
    inputRef,
    name,
    defaultValue,
    max,
    min,
  } = props
  const { t } = useTranslation()
  const { control, clearErrors } = formContext
  const [value, setValue] = useState(props.value)
  const { error } = fieldState
  const helperText = error?.message || props.helperText

  // Sync local state value with props.
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <FormControl fullWidth error={!!error} sx={{ gap: 1 }}>
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      <Controller
        name={name}
        control={control}
        defaultValue={isValidNumber(defaultValue) ? defaultValue : ''}
        rules={{
          max: {
            message: t('storyNumberField.mustNotBeGreaterThan', {
              value: max,
            }),
            value: max,
          },
          min: {
            message: t('storyNumberField.mustNotBeLessThan', {
              value: min,
            }),
            value: min,
          },
          required,
        }}
        render={({ field }) => (
          <OutlinedInput
            {...omit(field, 'ref')}
            inputRef={inputRef}
            color="secondary"
            inputProps={{
              inputMode: 'numeric',
              onWheel: (e: WheelEvent<HTMLInputElement>) =>
                e.currentTarget.blur(),
            }}
            type="text"
            placeholder={
              props.placeholder ?? t('storyNumberField.placeholder')
            }
            autoFocus={props.autoFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
              clearErrors()
              const value = parseNumberString(e.target.value)
              const valueAsNumber = parseFloat(value)
              const hasDecimalEnding = !/\.$/.test(String(value))
              const isValid =
                !!value && isFinite(Number(value)) && hasDecimalEnding

              setValue(value)
              props.onChange(isValid ? valueAsNumber : '')
            }}
            value={isValidNumber(value) ? value : ''}
            fullWidth
            disabled={props.disabled}
            startAdornment={
              startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              )
            }
            endAdornment={
              endAdornment && (
                <InputAdornment position="end">
                  {endAdornment}
                </InputAdornment>
              )
            }
            required={required}
            error={!!error}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
