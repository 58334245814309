import { useCallback, useEffect, useMemo, useRef } from 'react'
import { getCurrentTime } from '@shared/utils/DateUtil'

export default function useElapsedTime(enabled: boolean) {
  const startTimes = useRef<number[]>([])
  const endTimes = useRef<number[]>([])
  const getElapsedTime = useCallback(() => {
    return startTimes.current.reduce((acc, startTime, index) => {
      const endTime = endTimes.current[index] ?? getCurrentTime()
      return acc + (endTime - startTime)
    }, 0)
  }, [])
  const resetElapsedTime = useCallback(() => {
    startTimes.current = []
    endTimes.current = []
  }, [])
  const result = useMemo(
    () => ({
      getElapsedTime,
      resetElapsedTime,
    }),
    [getElapsedTime, resetElapsedTime],
  )

  useEffect(() => {
    if (enabled) {
      const time = getCurrentTime()
      startTimes.current.push(time)
    } else if (startTimes.current.length > endTimes.current.length) {
      const time = getCurrentTime()
      endTimes.current.push(time)
    }
  }, [enabled])

  return result
}
