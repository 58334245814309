import React, {
  Fragment,
  Ref,
  TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Stack, Typography } from '@mui/material'
import {
  BasePageProps,
  PageData,
} from 'components/StoryPlayer/StoryPage'
import StoryTextField, { TextField } from 'components/StoryTextField'
import StoryDateField, { DateField } from 'components/StoryDateField'
import StoryNumberField, {
  NumberField,
} from 'components/StoryNumberField'
import { useStoryPlayerProgress } from 'hooks/useStoryPlayerProgress'
import StorySelectField, {
  SelectField,
} from 'components/StorySelectField'
import StoryButtonField, {
  ButtonField,
} from 'components/StoryButtonField'
import StoryCheckboxField, {
  CheckboxField,
} from 'components/StoryCheckboxField'
import { useScroll, useTransform } from 'framer-motion'
import {
  Controller,
  ControllerFieldState,
  FieldValues,
  UseFormStateReturn,
  useForm,
} from 'react-hook-form'
import { TopLevelCondition } from 'json-rules-engine'
import PageBlock, {
  BlockData,
} from 'components/StoryPlayer/PageBlock'
import {
  MotionElement,
  MotionTrail,
} from 'components/MotionComponents'
import { scrollToError } from 'utils/FormUtil'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import ButtonBlock from 'components/blocks/ButtonBlock'
import RuleEngine from 'components/RuleEngine'
import { isMobile } from 'react-device-detect'
import PageFooter from 'components/StoryPlayer/PageFooter'
import { cloneDeep, isNil, isString } from 'lodash'
import { formatFormFields } from 'components/StoryPlayerUtil'
import useInterpolate, {
  useInterpolationData,
} from 'hooks/useInterpolate'
import { AccountSettings } from '@shared/constants'
import StoryWeightField, {
  WeightField,
} from 'components/StoryWeightField'
import StoryHeightField, {
  HeightField,
} from 'components/StoryHeightField'
import StoryAddressField, {
  AddressField,
  AddressValues,
} from 'components/StoryAddressField'
import StoryCholesterolField, {
  CholesterolField,
} from 'components/StoryCholesterolField'
import StoryGlucoseField, {
  GlucoseField,
} from 'components/StoryGlucoseField'
import StoryTriglyceridesField, {
  TriglyceridesField,
} from 'components/StoryTriglyceridesField'
import { interpolateDefaultValue } from 'utils/ObjectUtil'
import { useTranslation } from 'react-i18next'

export interface Field {
  name: string
  label?: string
  helperBlocks?: BlockData[]
  beforeBlock?: BlockData
  afterBlock?: BlockData
  required?: boolean
  defaultValue?: string | number
  displayConditions?: TopLevelCondition
  multiple?: never
}

export type ObjectField = Omit<Field, 'multiple'> & {
  type: 'object'
  multiple?: boolean
  fields: FormField[]
}

export type FormField =
  | TextField
  | DateField
  | NumberField
  | ButtonField
  | SelectField
  | CheckboxField
  | HeightField
  | WeightField
  | ObjectField
  | AddressField
  | CholesterolField
  | GlucoseField
  | TriglyceridesField

export interface FieldProps {
  inputRef: Ref<unknown>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<FieldValues>
  onBlur: (e: unknown) => void
  onChange: (e: unknown) => void
  value: string | number
  required?: boolean
  disableRequiredAsterisk?: boolean
}

function getFieldDisplayRuleEngine(fields: FormField[]) {
  const engine = new RuleEngine([], {
    allowUndefinedFacts: false,
  })

  fields.forEach((field) => {
    if (field.displayConditions) {
      engine.addRule({
        conditions: field.displayConditions,
        event: {
          params: {
            name: field.name,
          },
          type: 'display',
        },
      })
    }
  })
  return engine
}

export type StoryFieldProps = FormField & FieldProps

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValues = { [key: string]: any }

export interface PageFormData extends PageData {
  type: 'form'
  title?: string
  fields: FormField[]
  enableSkip?: boolean
  skipLabel?: string
}

export type PageFormProps = Pick<
  BasePageProps,
  | 'onNext'
  | 'disableMotion'
  | 'submitLabelOnEmpty'
  | 'enableClose'
  | 'submitLabel'
> &
  Omit<PageFormData, 'type'> & {
    id: string
    onSubmit: (
      data: Record<string, unknown>,
      fields: FormField[],
    ) => Promise<void>
    onSkip: () => void
    onBack: () => void
    onClose: () => void
    setHiddenTitle?: (value: boolean) => void
    disabled: boolean
  }

export default function PageForm(props: PageFormProps): JSX.Element {
  const { t } = useTranslation()
  const { id, enableSkip, submitLabelOnEmpty } = props
  const formRef = useRef<HTMLFormElement>(null)
  const isScrolledTop = useRef(true)
  const { scrollY } = useScroll({ container: formRef })
  const { resetProgress } = useStoryPlayerProgress()
  const interpolationData = useInterpolationData(StoryPlayerContext)
  const interpolate = useInterpolate(StoryPlayerContext)
  const {
    questionnaireResponses,
    questionnaireResponseItems,
    patient,
  } = useContext(StoryPlayerContext)
  const accountSettings = patient?.account
    ?.settings as AccountSettings
  const fieldFormatting =
    accountSettings?.fieldFormatting ??
    ({} as AccountSettings['fieldFormatting'])
  const [fields, setFields] = useState<FormField[]>([])
  const [submitting, setSubmitting] = useState(false)
  const disabled = props.disabled || submitting
  const formContext = useForm<FormValues>()
  const { control, handleSubmit, watch, getValues, reset } =
    formContext
  const [hiddenFields, setHiddenFields] = useState<string[]>([])
  const hasFormData = !!fields
    .flatMap(({ name }) => watch(name))
    .filter(Boolean)?.length
  const toggleConditionalFields = useCallback(
    (fields: FormField[]) => {
      const facts = fields.reduce((acc, field) => {
        acc[field.name] = getValues(field.name) ?? undefined
        return acc
      }, [])
      const fieldDisplayRuleEngine = getFieldDisplayRuleEngine(fields)

      fieldDisplayRuleEngine.run(facts).then(({ events }) => {
        const displayableFields = events.map(
          (event) => event.params?.name,
        )
        setHiddenFields(
          fields.reduce((acc, field) => {
            if (field.displayConditions) {
              if (!displayableFields.includes(field.name)) {
                acc.push(field.name)
              }
            }
            return acc
          }, []),
        )
      })
    },
    [getValues],
  )
  const isSingleField = fields.length === 1
  const hasRelativeSubmit =
    isSingleField &&
    !['object', 'button', 'checkbox'].includes(fields[0].type)
  // Hide submit button for single button field case.
  const hiddenSubmit = !fields.every(({ type }) => type === 'button')
  const pageFooter = (
    <PageFormFooter
      enableClose={props.enableClose}
      enableSkip={enableSkip}
      hasFormData={hasFormData}
      hasRelativeSubmit={hasRelativeSubmit}
      hiddenSubmit={hiddenSubmit}
      onClose={props.onClose}
      onSkip={props.onSkip}
      submitLabel={props.submitLabel}
      submitLabelOnEmpty={submitLabelOnEmpty}
      skipLabel={props.skipLabel}
      submitting={disabled}
      disableMotion={props.disableMotion}
    />
  )

  // Initialize form and fields on page change.
  useEffect(() => {
    // Set new fields and reset form.
    const indexes = props.id
      .split('.')
      .slice(1)
      .map((i) => parseInt(i))

    // Interpolate field names with indexes from page id.
    props.fields.forEach((field) => {
      field.name = indexes.reduce(
        (acc, index) => acc.replace(`[]`, `[${index}]`),
        field.name,
      )
    })

    setFields(props.fields)
    toggleConditionalFields(props.fields)
  }, [props.fields, props.id, toggleConditionalFields])

  // Sync form values with latest questionnaire responses.
  useEffect(() => {
    reset(cloneDeep(questionnaireResponses))
  }, [questionnaireResponses, reset])

  useEffect(() => {
    resetProgress()
  }, [resetProgress])

  useTransform(scrollY, (value) => {
    const scrolledTop = value <= 0
    if (scrolledTop !== isScrolledTop.current) {
      isScrolledTop.current = scrolledTop
      props.setHiddenTitle?.(!scrolledTop)
    }
  })

  return (
    <Stack
      ref={formRef}
      component="form"
      noValidate
      onSubmit={handleSubmit(async (data) => {
        setSubmitting(true)
        await props.onSubmit(
          data,
          formatFormFields(fields, questionnaireResponseItems),
        )
        setSubmitting(false)
      }, scrollToError)}
      sx={{
        '& .MuiInputBase-root': {
          bgcolor: 'background.default',
        },
        flexGrow: 1,
        justifyContent: hasRelativeSubmit
          ? 'flex-start'
          : 'space-between',
        position: 'relative',
        pt: 'clamp(64px, 15cqh, 128px)',
        transition: 'opacity 0.5s ease 0s',
      }}
      onTouchStart={(e: TouchEvent) => {
        // Disable drawer swipe to close to allow scrollable forms.
        // Swipe to close is still allowed from the story header.
        const { clientHeight, scrollHeight } = e.currentTarget
        if (clientHeight < scrollHeight) {
          e.stopPropagation()
        }
      }}
    >
      <Stack
        sx={[
          {
            justifyContent: 'flex-start',
            mt: 2,
            pb: enableSkip ? 16 : 8,
            px: 3,
          },
          isSingleField && {
            '& .MuiInputAdornment-positionEnd': {
              position: 'absolute',
              right: 20,
            },
            '& .MuiInputBase-input[type=number]': {
              textAlign: 'center',
            },
          },
        ]}
      >
        <MotionTrail
          id={id}
          items={[
            props.title && (
              <MotionElement>
                <Typography variant="h3" color="textPrimary">
                  {props.title}
                </Typography>
              </MotionElement>
            ),
            ...fields.map((fieldProps, index) => {
              // Determine conditional field visibility.
              const isHidden =
                !!fieldProps.displayConditions &&
                hiddenFields.includes(fieldProps.name)
              const multiple =
                !!fieldProps?.multiple ||
                fieldProps?.type === 'checkbox'
              const defaultValue =
                // Interpolate default value.
                (isString(fieldProps.defaultValue)
                  ? interpolate(fieldProps.defaultValue)
                  : fieldProps.defaultValue) ?? (multiple ? [] : '')
              const dateFormat =
                fieldProps?.type === 'date'
                  ? fieldProps?.format ||
                    fieldFormatting.date ||
                    'MM/DD/YYYY'
                  : null

              return (
                <Fragment
                  key={`PageBlock.${fieldProps.name}.${index}`}
                >
                  {fieldProps.beforeBlock && (
                    <MotionElement sx={{ mb: 3 }}>
                      <PageBlock {...fieldProps.beforeBlock} />
                    </MotionElement>
                  )}
                  <Controller
                    name={fieldProps.name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={{
                      maxLength:
                        fieldProps.type === 'text'
                          ? {
                              message: t(
                                'pageForm.mustNotBeMoreThan',
                                { value: fieldProps.maxLength },
                              ),
                              value: fieldProps.maxLength,
                            }
                          : undefined,
                      minLength:
                        fieldProps.type === 'text'
                          ? {
                              message: t(
                                'pageForm.mustNotBeLessThan',
                                { value: fieldProps.minLength },
                              ),
                              value: fieldProps.minLength,
                            }
                          : undefined,
                      pattern: fieldProps.type === 'date' && {
                        message: t('pageForm.invalidDate', {
                          value: dateFormat,
                        }),
                        // Input collected as MM/DD/YYYY using mask input
                        // but validated as YYYY-MM-DD for storage.
                        value: /^\d{4}-\d{2}-\d{2}$/,
                      },
                      required: isHidden
                        ? false
                        : fieldProps.required,
                    }}
                    render={({ field, fieldState, formState }) => {
                      // Hide conditionally displayed fields.
                      if (isHidden) {
                        return null
                      }
                      const formFieldProps = {
                        fieldState: fieldState,
                        formState: formState,
                        inputRef: field.ref,
                        onBlur: field.onBlur,
                        onChange: (e) => {
                          field.onChange(e)
                          toggleConditionalFields(props.fields)
                        },
                        value: !isNil(field.value)
                          ? field.value
                          : multiple // Use expected fallback for multiple select.
                            ? []
                            : !isNil(defaultValue)
                              ? defaultValue
                              : '',
                      }
                      const autoFocus = !isMobile && index === 0
                      const singleField = fields.length === 1

                      return (
                        <MotionElement
                          sx={{ mb: fieldProps.afterBlock ? 3 : 5 }}
                        >
                          {fieldProps.type === 'text' && (
                            <StoryTextField
                              {...fieldProps}
                              {...formFieldProps}
                              placeholder={fieldProps.placeholder}
                              autoFocus={autoFocus}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'date' && (
                            <StoryDateField
                              {...fieldProps}
                              {...formFieldProps}
                              format={dateFormat}
                              autoFocus={autoFocus}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'number' && (
                            <StoryNumberField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              autoFocus={autoFocus}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'select' && (
                            <StorySelectField
                              {...fieldProps}
                              {...formFieldProps}
                              autoFocus={autoFocus}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'button' && (
                            <StoryButtonField
                              {...fieldProps}
                              {...formFieldProps}
                              disableSubmit={hiddenSubmit}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'checkbox' && (
                            <StoryCheckboxField
                              {...fieldProps}
                              {...formFieldProps}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'weight' && (
                            <StoryWeightField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              unit={fieldFormatting.weight}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'height' && (
                            <StoryHeightField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              unit={fieldFormatting.height}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'cholesterol' && (
                            <StoryCholesterolField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              unit={fieldFormatting.cholesterol}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'glucose' && (
                            <StoryGlucoseField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              unit={fieldFormatting.cholesterol}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'triglycerides' && (
                            <StoryTriglyceridesField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              unit={fieldFormatting.cholesterol}
                              disabled={disabled}
                              disableRequiredAsterisk={singleField}
                            />
                          )}
                          {fieldProps.type === 'address' && (
                            <StoryAddressField
                              {...fieldProps}
                              {...formFieldProps}
                              formContext={formContext}
                              autoFocus={autoFocus}
                              disabled={disabled}
                              defaultValue={interpolateDefaultValue<AddressValues>(
                                String(fieldProps.defaultValue ?? ''),
                                interpolationData,
                              )}
                              defaultCountryCode={interpolate(
                                fieldProps.defaultCountryCode,
                              )}
                            />
                          )}
                        </MotionElement>
                      )
                    }}
                  />
                  {fieldProps.afterBlock && (
                    <MotionElement sx={{ mb: 5 }}>
                      <PageBlock {...fieldProps.afterBlock} />
                    </MotionElement>
                  )}
                </Fragment>
              )
            }),
            hasRelativeSubmit && pageFooter,
          ].filter(Boolean)}
        />
      </Stack>
      {!hasRelativeSubmit && pageFooter}
    </Stack>
  )
}

type PageFormFooterProps = {
  enableClose: boolean
  enableSkip: boolean
  hasFormData: boolean
  hasRelativeSubmit: boolean
  hiddenSubmit: boolean
  onClose: PageFormProps['onClose']
  onSkip: PageFormProps['onSkip']
  submitLabel: string
  submitLabelOnEmpty: string
  skipLabel: string
  submitting: boolean
  disableMotion: boolean
}

function PageFormFooter(props: PageFormFooterProps) {
  const { t } = useTranslation()
  const {
    hasRelativeSubmit,
    hiddenSubmit,
    submitting,
    hasFormData,
    disableMotion,
    enableSkip,
    skipLabel = t('pageForm.skip'),
    submitLabel = t('pageForm.next'),
  } = props
  const submitLabelOnEmpty = props.submitLabelOnEmpty ?? submitLabel
  const pageFooter = (
    <PageFooter
      key="pageFooter"
      disableScrollHint={hasRelativeSubmit}
      sx={hasRelativeSubmit ? { position: 'relative', px: 0 } : null}
    >
      {enableSkip && (
        <ButtonBlock
          type="button"
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          onClick={props.onSkip}
        >
          {skipLabel}
        </ButtonBlock>
      )}
      {hiddenSubmit && (
        <ButtonBlock
          type="submit"
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          loading={submitting}
        >
          {hasFormData ? submitLabel : submitLabelOnEmpty}
        </ButtonBlock>
      )}
      {props.enableClose && (
        <ButtonBlock
          type="button"
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          onClick={props.onClose}
        >
          {t('pageForm.done')}
        </ButtonBlock>
      )}
    </PageFooter>
  )
  if (hasRelativeSubmit) {
    return (
      <MotionElement disableMotion={disableMotion}>
        {pageFooter}
      </MotionElement>
    )
  }
  return pageFooter
}
