import React from 'react'
import {
  FormLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import {
  Field,
  FieldProps,
  FormValues,
} from 'components/StoryPlayer/pages/PageForm'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import StoryFieldLabel from 'components/StoryFieldLabel'
import HelperBlocks from './HelperBlocks'
import { Address } from '__generated__/graphql'
import { Controller, UseFormReturn } from 'react-hook-form'
import { omit } from 'lodash'
import { playerMaxHeight } from './StoryPlayer'
import MarkdownContent from './MarkdownContent'
import { countryOptions, getStateOptions } from 'utils/AddressUtil'
import { useTranslation } from 'react-i18next'

export type AddressValues = Pick<
  Address,
  'line1' | 'line2' | 'country' | 'city' | 'state' | 'postalCode'
>

export interface AddressField extends Omit<Field, 'defaultValue'> {
  type: 'address'
  defaultValue?: AddressValues | string
  defaultCountryCode?: string // Country ISO code.
}

interface StoryAddressFieldProps
  extends AddressField,
    Omit<FieldProps, 'onBlur' | 'value' | 'ref'> {
  autoFocus?: boolean
  disabled?: boolean
  formContext: UseFormReturn<FormValues>
}

export default function StoryAddressField(
  props: StoryAddressFieldProps,
) {
  const { t } = useTranslation()
  const {
    helperBlocks,
    label,
    name,
    formContext,
    autoFocus,
    required,
    disabled,
    defaultValue,
  } = props
  const defaultCountryCode =
    (defaultValue as AddressValues)?.country ||
    props.defaultCountryCode ||
    'US'
  const { control, watch, setValue } = formContext
  const selectedCountry =
    watch(`${name}.country`) || defaultCountryCode
  const defaultAddress: AddressValues = {
    city: (defaultValue as AddressValues)?.city ?? '',
    country: defaultCountryCode ?? '',
    line1: (defaultValue as AddressValues)?.line1 ?? '',
    line2: (defaultValue as AddressValues)?.line2 ?? '',
    postalCode: (defaultValue as AddressValues)?.postalCode ?? '',
    state: (defaultValue as AddressValues)?.state ?? '',
  }
  const stateOptions = getStateOptions(selectedCountry)
  const hasStateOptions = !!stateOptions?.length

  return (
    <Stack>
      <StoryFieldLabel label={label} sx={{ mb: 2 }} />
      <HelperBlocks blocks={helperBlocks} />
      <Stack
        sx={{
          '& .MuiFormLabel-root': {
            '&.Mui-focused:not(.Mui-error)': {
              color: 'text.secondary',
            },
            mb: 0,
          },
          gap: 1,
        }}
      >
        <Controller
          name={`${name}.line1`}
          control={control}
          rules={{ required }}
          defaultValue={defaultAddress.line1}
          render={({ field, fieldState }) => (
            <FormControl
              fullWidth
              error={!!fieldState.error}
              disabled={disabled}
            >
              <FormLabel required={required}>
                {t('storyAddressField.form.label.line1')}
              </FormLabel>
              <OutlinedInput
                {...omit(field, ['ref'])}
                color="secondary"
                error={!!fieldState.error}
                autoFocus={autoFocus}
              />
            </FormControl>
          )}
        />
        <Controller
          name={`${name}.line2`}
          control={control}
          rules={{ required: false }}
          defaultValue={defaultAddress.line2}
          render={({ field, fieldState }) => (
            <FormControl
              fullWidth
              error={!!fieldState.error}
              disabled={disabled}
            >
              <FormLabel>
                {t('storyAddressField.form.label.line2')}
              </FormLabel>
              <OutlinedInput
                {...omit(field, ['ref'])}
                color="secondary"
                error={!!fieldState.error}
              />
            </FormControl>
          )}
        />
        <Controller
          name={`${name}.country`}
          control={control}
          rules={{ required }}
          defaultValue={defaultAddress.country}
          render={({ field, fieldState }) => (
            <FormControl
              fullWidth
              error={!!fieldState.error}
              disabled={disabled}
            >
              <FormLabel required={required}>
                {t('storyAddressField.form.label.country')}
              </FormLabel>
              <Select
                {...omit(field, ['ref'])}
                onChange={(e) => {
                  setValue(`${name}.state`, '')
                  field.onChange(e)
                }}
                fullWidth
                MenuProps={{
                  color: 'secondary',
                  sx: {
                    '& .MuiMenuItem-root': {
                      '&.Mui-selected': {
                        '&:hover': {
                          bgcolor: 'secondary.dark',
                          color: 'secondary.contrastText',
                        },
                        bgcolor: 'secondary.main',
                        color: 'secondary.contrastText',
                      },
                      whiteSpace: 'normal',
                    },
                    maxHeight: playerMaxHeight,
                  },
                }}
                sx={{
                  '& .MuiSelect-select': {
                    '& .MuiBox-root > *': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                    '& p:first-of-type': { mt: 0 },
                    '& p:last-of-type': { mb: 0 },
                    '&:has(.MuiChip-root)': {
                      // Fix height discrepancies when Chips are displayed.
                      my: '-0.28rem',
                    },
                  },
                }}
                required={required}
                error={!!fieldState.error}
                renderValue={(value) => {
                  const label = countryOptions.find(
                    (option) => option.value === value,
                  )?.label

                  return <MarkdownContent value={label} />
                }}
              >
                {countryOptions.map((item, index) =>
                  item.isCategory ? (
                    <ListSubheader key={`category-${index}`}>
                      {item.label}
                    </ListSubheader>
                  ) : (
                    <MenuItem
                      key={`item-${item.value}-${index}`}
                      value={item.value}
                    >
                      <MarkdownContent value={item.label} />
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
        {hasStateOptions && (
          <Controller
            name={`${name}.state`}
            control={control}
            rules={{ required }}
            defaultValue={defaultAddress.state}
            render={({ field, fieldState }) => (
              <FormControl
                fullWidth
                error={!!fieldState.error}
                disabled={disabled}
              >
                <FormLabel required={required}>
                  {t('storyAddressField.form.label.state')}
                </FormLabel>
                <Select
                  {...omit(field, ['ref'])}
                  displayEmpty={true}
                  fullWidth
                  MenuProps={{
                    color: 'secondary',
                    sx: {
                      '& .MuiMenuItem-root': {
                        '&.Mui-selected': {
                          '&:hover': {
                            bgcolor: 'secondary.dark',
                            color: 'secondary.contrastText',
                          },
                          bgcolor: 'secondary.main',
                          color: 'secondary.contrastText',
                        },
                        whiteSpace: 'normal',
                      },
                      maxHeight: playerMaxHeight,
                    },
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      '& .MuiBox-root > *': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      },
                      '& p:first-of-type': { mt: 0 },
                      '& p:last-of-type': { mb: 0 },
                      '&:has(.MuiChip-root)': {
                        // Fix height discrepancies when Chips are displayed.
                        my: '-0.28rem',
                      },
                    },
                  }}
                  required={required}
                  error={!!fieldState.error}
                  renderValue={(value) => {
                    const label = stateOptions.find(
                      (option) => option.value === value,
                    )?.label
                    return <MarkdownContent value={label} />
                  }}
                >
                  {stateOptions.map((option, index) => (
                    <MenuItem
                      key={`item-${option.value}-${index}`}
                      value={option.value}
                    >
                      <MarkdownContent value={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        )}
        <Controller
          name={`${name}.city`}
          control={control}
          rules={{ required }}
          defaultValue={defaultAddress.city}
          render={({ field, fieldState }) => (
            <FormControl
              fullWidth
              error={!!fieldState.error}
              disabled={disabled}
            >
              <FormLabel required={required}>
                {t('storyAddressField.form.label.city')}
              </FormLabel>
              <OutlinedInput
                {...omit(field, ['ref'])}
                color="secondary"
                error={!!fieldState.error}
              />
            </FormControl>
          )}
        />
        <Controller
          name={`${name}.postalCode`}
          control={control}
          rules={{ required }}
          defaultValue={defaultAddress.postalCode}
          render={({ field, fieldState }) => (
            <FormControl
              fullWidth
              error={!!fieldState.error}
              disabled={disabled}
            >
              <FormLabel required={required}>
                {t('storyAddressField.form.label.postalCode')}
              </FormLabel>
              <OutlinedInput
                {...omit(field, ['ref'])}
                inputProps={{
                  inputMode: 'numeric',
                }}
                color="secondary"
                error={!!fieldState.error}
              />
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  )
}
