import ConfirmationDialog from 'components/ConfirmationDialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type SessionInactiveDialogProps = {
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

export default function SessionInactiveDialog({
  onConfirm,
  open,
  onClose,
}: SessionInactiveDialogProps) {
  const { t } = useTranslation()
  return (
    <ConfirmationDialog
      open={open}
      title={t('sessionInactiveDialog.title')}
      message={t('sessionInactiveDialog.message')}
      cancelLabel={t('sessionInactiveDialog.cancelLabel')}
      confirmLabel={t('sessionInactiveDialog.confirmLabel')}
      onConfirm={onConfirm}
      onClose={onClose}
      slotProps={{
        backdrop: { sx: { backdropFilter: 'blur(5px)' } },
      }}
    />
  )
}
