import React, { useCallback, useContext, useMemo } from 'react'
import Handlebars from 'handlebars'
import {
  get,
  isArray,
  isBoolean,
  isDate,
  isString,
  isUndefined,
  upperCase,
} from 'lodash'
import PatientModel from 'models/Patient'
import {
  PatientAppAccount,
  PatientAppPatientData,
} from '__generated__/graphql'
import { StoryPlayerContextProps } from 'components/StoryPlayerContext'
import { getAnswerLabel } from 'components/StoryPlayerUtil'
import { format, utcToZonedTime } from 'date-fns-tz'
import { formatAge, formatDate } from '@shared/utils/DateUtil'
import useStorySettings from 'hooks/useStorySettings'
import { formatTel } from '@shared/utils/TelUtil'
import { RegionCode } from 'google-libphonenumber'
import { AccountSettings } from '@shared/constants'
import LocalStorageUtil from 'utils/LocalStorageUtil'
import i18n from '../i18n'
import CareStepOccurrenceModel from 'models/CareStepOccurrence'

// Register Handlebars helpers for data formatting.
Handlebars.registerHelper(
  ':Date',
  (isoDate: string, dateFormat?: string) => {
    isoDate = isDate(isoDate) ? isoDate.toISOString() : isoDate
    // Validate date format.
    dateFormat = isString(dateFormat) ? dateFormat : undefined

    // Validate ISO date.
    if (!isoDate?.match(/^\d{4}-\d{2}-\d{2}/)) {
      console.warn('Invalid date', isoDate)
      return i18n.t('useInterpolate.invalidDate')
    }
    return formatDate(isoDate, dateFormat)
  },
)
Handlebars.registerHelper(
  ':AnswerLabel',
  (name: string, index?: number) => {
    // Support interpolating index.
    if (!isUndefined(index)) {
      name = name.replace('{{index}}', `${index}`)
    }

    const { questionnaireResponseItems } = globalContext
    const itemName = name?.match(/^\w+/)?.[0]
    const item = questionnaireResponseItems.find(
      (item) => item.name === itemName,
    )

    // Determine label for object types.
    if (item?.type === 'OBJECT') {
      const fieldName = name
        // Remove root object name.
        .replace(/^\w+(\[\d+\])?\./, '')
        // Replace array index with [].
        .replace(/\[.*?\]/g, '[]')
      const value = get({ [itemName]: item.answer }, name)
      const label = getAnswerLabel(
        fieldName,
        value,
        item.metadata?.fields,
      )
      return label
    }

    return (
      questionnaireResponseItems.find((item) => item?.name === name)
        ?.answerLabel ?? ''
    )
  },
)
Handlebars.registerHelper(':Lowercase', (label = '') =>
  label.toLocaleLowerCase(),
)
Handlebars.registerHelper(':Length', (value: unknown) => {
  return isArray(value) ? value.length : 0
})
Handlebars.registerHelper(':Add', (value: number, num: number) => {
  return value + num
})
Handlebars.registerHelper(
  ':Tel',
  (
    e164Number: string,
    region?: RegionCode,
    internationalFormat?: boolean,
  ) => {
    const defaultRegion = upperCase(
      globalContext.interpolationData.account?.settings
        ?.fieldFormatting?.phoneCountryCode ?? 'US',
    ) as RegionCode

    // Parse string as it can differ if it's not provided.
    region = isString(region) ? region : defaultRegion

    // Parse boolean as it can differ if it's not provided.
    internationalFormat = isBoolean(internationalFormat)
      ? internationalFormat
      : region !== 'US'

    // Parse e164 number.
    e164Number = `+${e164Number?.replace(/\D/g, '')}`

    const formattedTel = formatTel(
      e164Number,
      region,
      internationalFormat,
    )
    return `[${formattedTel}](tel:${e164Number})`
  },
)

// Support for relational operators for handlebar templates.
// Usage: {{#operation patientDataModel.anxietyScore '==' 8}}
// Usage: {{#if (operation patientDataModel.anxietyScore '>=' 8)}}
Handlebars.registerHelper(
  'operation',
  (value1, operator, value2, options) => {
    const operators = {
      '!=': (v1, v2) => v1 !== v2,
      '&&': (v1, v2) => v1 && v2,
      '<': (v1, v2) => v1 < v2,
      '<=': (v1, v2) => v1 <= v2,
      '==': (v1, v2) => v1 === v2,
      '>': (v1, v2) => v1 > v2,
      '>=': (v1, v2) => v1 >= v2,
      in: (v1, v2) => v2.includes(v1),
      '||': (v1, v2) => v1 || v2,
    }

    if (!operators[operator]) {
      throw new Error('Unsupported operator')
    }

    // If no block is provided, return a boolean indicating whether the condition is met
    if (!options.fn) {
      return operators[operator](value1, value2)
    }

    //If operation evaluates to true then execute "truthy" block
    if (operators[operator](value1, value2)) {
      return options.fn(this)
    }
    //Otherwise execute the "falsey" block {{else}}
    return options.inverse(this)
  },
)

export type InterpolationData = ReturnType<
  typeof useInterpolationData
>

const globalContext: {
  interpolationData: InterpolationData
  questionnaireResponses: BaseInterpolationData['questionnaireResponses']
  questionnaireResponseItems: BaseInterpolationData['questionnaireResponseItems']
} = {
  interpolationData: null,
  questionnaireResponseItems: [],
  questionnaireResponses: {},
}

interface BaseInterpolationData {
  careStepOccurrences: StoryPlayerContextProps['careStepOccurrences']
  patient: PatientModel
  patientData: PatientAppPatientData[]
  questionnaireResponses: StoryPlayerContextProps['questionnaireResponses']
  questionnaireResponseItems: StoryPlayerContextProps['questionnaireResponseItems']
  story: StoryPlayerContextProps['story']
}

export function useInterpolationData<T extends BaseInterpolationData>(
  context: React.Context<T>,
) {
  const {
    careStepOccurrences = [],
    patient,
    questionnaireResponses,
    patientData,
    story,
  } = useContext<T>(context)
  const timeZone = patient?.account?.timezone ?? 'UTC'
  const { storySettings: accountStorySettings } = useStorySettings(
    story?.storySettingsSchemaProperties,
    patient?.account?.storySettings,
  )
  const appointmentDate = useMemo(() => {
    return new Date(patient?.upcomingAppointment?.appointmentDate)
  }, [patient])
  const isValidAppointmentDate = !isNaN(appointmentDate.getTime())
  const account: Omit<PatientAppAccount, 'settings'> & {
    settings: AccountSettings
  } = useMemo(
    () => ({
      ...patient?.account,
      settings: (patient?.account?.settings ?? {}) as AccountSettings,
    }),
    [patient],
  )
  const result = useMemo(() => {
    // Add a map for care step occurrences to be referenced by care step code id.
    // e.g. {{careStepOccurrence.screening-mammogram.careStep.name}}
    // e.g. {{:Date careStepOccurrence.screening-mammogram.lastPerformedAt}}
    const careStepOccurrence = Object.values(
      careStepOccurrences,
    ).reduce(
      (acc, item) => {
        acc[item.careStepCodeId] = new CareStepOccurrenceModel(item)
        return acc
      },
      {} as Record<
        string,
        StoryPlayerContextProps['careStepOccurrences'][0]
      >,
    )
    return {
      account,
      accountStorySettings,
      careStepOccurrence,
      patient: {
        ...(patient ?? {}),
        age: patient?.birthDate ? formatAge(patient.birthDate) : null,
        mostRecentPositiveResult: patient?.mostRecentPositiveResult,
        upcomingAppointment: {
          ...(patient?.upcomingAppointment ?? {}),
          formattedAppointmentDate: isValidAppointmentDate
            ? format(
                utcToZonedTime(
                  appointmentDate,
                  Intl.DateTimeFormat().resolvedOptions().timeZone ??
                    timeZone, //fallback to account timezone if we can't get the local timezone
                ),
                'PP',
              )
            : null,
        },
      },
      patientData: (patientData ?? []).reduce((acc, item) => {
        acc[item.fieldName] = item.value
        return acc
      }, {}),
      questionnaireResponses: questionnaireResponses ?? {},
    }
  }, [
    account,
    accountStorySettings,
    appointmentDate,
    careStepOccurrences,
    isValidAppointmentDate,
    patient,
    patientData,
    questionnaireResponses,
    timeZone,
  ])
  return result
}

/**
 * Retrieve localStorage data for keys referenced in text.
 * @param text content including mustache variable references.
 * @returns object with localStorage keys and values.
 * @example
 * getLocalStorageData('Last Activity: {{localStorage.lastActivity}}')
 * // { lastActivity: '2022-01-01T00:00:00.000Z' }
 */
function getLocalStorageData(text: string) {
  // Extract localStorage keys.
  const localStorageKeys = [
    ...text.matchAll(/\{\{localStorage\.(.+?)\}\}/g),
  ].map((item) => item[1])
  return localStorageKeys.reduce((acc, key) => {
    // Retrieve localStorage data.
    acc[key] = LocalStorageUtil.getItem(key)
    return acc
  }, {})
}

export default function useInterpolate<
  T extends BaseInterpolationData,
>(context: React.Context<T>) {
  const { questionnaireResponses, questionnaireResponseItems } =
    useContext<T>(context)
  const interpolationData = useInterpolationData(context)
  const result = useCallback(
    (text = '', additionalData?: Record<string, unknown>): string => {
      try {
        return Handlebars.compile(text)({
          ...interpolationData,
          ...additionalData,
          localStorage: getLocalStorageData(text),
        })
      } catch (e) {
        console.error(e)
        return text
      }
    },
    [interpolationData],
  )

  globalContext.interpolationData = interpolationData
  globalContext.questionnaireResponses = questionnaireResponses
  globalContext.questionnaireResponseItems =
    questionnaireResponseItems
  return result
}
