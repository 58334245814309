import { PaletteMode } from '@mui/material'
import { FeatureFlags } from 'components/AppContext'
import { ShareRecipientValues } from 'components/share/ShareRecipientDialog'
import LocalStorageUtil from 'utils/LocalStorageUtil'

export type UserStoreState = {
  'user.featureFlags': FeatureFlags
  'user.fontSize': number
  'user.shareRecipients': ShareRecipientValues[]
  'user.themeMode': PaletteMode
  'user.preferredLanguage': string
}

export default class UserStore {
  static get<T extends keyof UserStoreState>(key: T) {
    const value: UserStoreState[T] = LocalStorageUtil.getItem(key)
    UserStore.log('get', key, value)
    return value
  }

  static set<T extends keyof UserStoreState>(
    key: T,
    value: UserStoreState[T],
  ) {
    UserStore.log('set', key, value)
    LocalStorageUtil.setItem(key, value)
  }

  static reset() {
    UserStore.log('reset')
    const keys: (keyof UserStoreState)[] = [
      'user.featureFlags',
      'user.fontSize',
      'user.shareRecipients',
      'user.themeMode',
    ]
    // Remove all related local storage items.
    keys.forEach((key) => LocalStorageUtil.removeItem(key))
  }

  static log(...rest) {
    if (localStorage.getItem('debugEnabled')) {
      console.log(
        '%cUserStore',
        'border-radius:4px; padding:0 4px; color:white; background-color:#9b8248;',
        ...rest,
      )
    }
  }
}
