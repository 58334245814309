import { useAuth } from '@frontegg/react'
import { PaletteMode, Theme } from '@mui/material'
import {
  useCareStepOccurrencesLazyQuery,
  useLoggedInPatientLazyQuery,
  usePatientDataLazyQuery,
  useTodosLazyQuery,
} from '__generated__/graphql'
import {
  FontSize,
  getPreferredThemeMode,
  getTheme,
} from 'components/NestMuiTheme'
import StoryPlayerProvider from 'components/StoryPlayerProvider'
import defaultContentAccounts from 'data/MockContentAccounts'
import mockPatientData from 'data/MockPatientData'
import mockStories from 'data/MockStories'
import { useScroll } from 'framer-motion'
import useRouteCheck from 'hooks/useRouteCheck'
import { isUndefined } from 'lodash'
import PatientModel from 'models/Patient'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import UserStore from 'stores/UserStore'
import { AppContext } from './AppContext'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useEffectOnceWhen } from 'rooks'
import * as Sentry from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'

export default function AppProvider(props: { children: ReactNode }) {
  const { isAuthenticated } = useAuth()
  const { isAuthRequired } = useRouteCheck()
  const [
    fetchLoggedInPatient,
    {
      data: patientResult,
      refetch: refetchPatient,
      loading: patientLoading,
      error: loggedInPatientError,
    },
  ] = useLoggedInPatientLazyQuery()
  const [fetchPatientData, { data: patientDataResult }] =
    usePatientDataLazyQuery()
  const refetchPatientData = useCallback(async () => {
    await fetchPatientData()
    // Add short delay to allow data to be propagated.
    await new Promise((resolve) => window.setTimeout(resolve, 100))
  }, [fetchPatientData])
  const [
    fetchCareStepOccurrences,
    { data: careStepOccurrencesData },
  ] = useCareStepOccurrencesLazyQuery()
  const [fetchTodos, { data: todosData, loading: todosLoading }] =
    useTodosLazyQuery()
  const todos = useMemo(
    () => todosData?.patientApp?.todos,
    [todosData?.patientApp?.todos],
  )
  const stories = useMemo(() => mockStories, [])
  const [hasBackNavigation, setHasBackNavigation] = useState(false)
  const [documentTitle, setDocumentTitle] = useState('')
  const [fontSize, setFontSize] = useState(FontSize.Default)
  const [themeMode, setThemeMode] = useState<PaletteMode>(
    getPreferredThemeMode(),
  )
  const [theme, setTheme] = useState<Theme>(getTheme())
  const { i18n } = useTranslation()
  const { scrollY } = useScroll()
  const loggedInPatient = patientResult?.patientApp?.loggedInPatient
  const patient = useMemo(() => {
    if (loggedInPatient) {
      return new PatientModel(loggedInPatient, {
        ...mockPatientData,
      })
    }

    // When patient doesn't exist for the user in our system.
    if (!loggedInPatient && !isUndefined(patientResult)) {
      return null
    }

    // When patient is still loading.
    return undefined
  }, [loggedInPatient, patientResult])
  const patientData = useMemo(
    () => patientDataResult?.patientApp?.patientData || [],
    [patientDataResult?.patientApp?.patientData],
  )
  const careStepOccurrences = useMemo(
    () =>
      careStepOccurrencesData?.patientApp?.careStepOccurrences || [],
    [careStepOccurrencesData?.patientApp?.careStepOccurrences],
  )
  const ready = isAuthRequired ? !isUndefined(patient) : true
  const featureFlags = useFeatureFlags(patient?.account?.settings)
  const { customLearnAccounts } = featureFlags
  const contentAccounts = useMemo(
    () =>
      [...customLearnAccounts, ...defaultContentAccounts].filter(
        Boolean,
      ),
    [customLearnAccounts],
  )

  // Fetch patient data records on patient login.
  useEffect(() => {
    if (loggedInPatient) {
      fetchPatientData()
      fetchCareStepOccurrences()
    }
  }, [loggedInPatient, fetchPatientData, fetchCareStepOccurrences])

  useEffect(() => {
    setFontSize(UserStore.get('user.fontSize') ?? FontSize.Default)
    setThemeMode(
      UserStore.get('user.themeMode') || getPreferredThemeMode(),
    )
  }, [])

  // Throw logged in patient error to be caught by error boundary.
  useEffect(() => {
    if (loggedInPatientError) {
      throw loggedInPatientError
    }
  }, [loggedInPatientError])

  useEffect(() => {
    setTheme(getTheme({ fontSize, mode: themeMode }))
    // Set font size on html element for elements using rem units.
    document.documentElement.style.fontSize = `${fontSize}px`
  }, [fontSize, themeMode])

  // Fetch patient on mount.
  useEffectOnceWhen(() => {
    if (isAuthRequired && isAuthenticated) {
      fetchLoggedInPatient()
    }
  }, isAuthRequired && isAuthenticated)

  // Initialize user language with logged in patient.
  useEffectOnceWhen(() => {
    if (loggedInPatient) {
      const supportedLanguages = (i18n.options.supportedLngs ||
        []) as string[]
      const language =
        // Prioritize preferred language over patient language.
        UserStore.get('user.preferredLanguage') || patient.language
      if (supportedLanguages.includes(language)) {
        i18n.changeLanguage(language)
      }
    }
  }, !!loggedInPatient)

  // Set Sentry user context on patient login.
  useEffectOnceWhen(() => {
    if (loggedInPatient) {
      Sentry.setUser({ id: loggedInPatient.user.id })
      Sentry.setContext('patientUser', {
        accountId: loggedInPatient.account.id,
        fronteggId: loggedInPatient.user.fronteggId,
        patientId: loggedInPatient.id,
        userId: loggedInPatient.user.id,
      })
    }
  }, !!loggedInPatient)

  return (
    <AppContext.Provider
      value={{
        contentAccounts,
        documentTitle,
        featureFlags,
        fetchTodos,
        fontSize,
        hasBackNavigation,
        patient,
        patientData,
        patientLoading,
        ready,
        refetchPatient,
        scrollY,
        setDocumentTitle,
        setFontSize,
        setHasBackNavigation,
        setTheme,
        setThemeMode,
        stories,
        theme,
        themeMode,
        todos,
        todosLoading,
      }}
    >
      <StoryPlayerProvider
        patient={patient}
        patientData={patientData}
        careStepOccurrences={careStepOccurrences}
        isAuthenticated={isAuthenticated}
        refetchPatientData={refetchPatientData}
      >
        {props.children}
      </StoryPlayerProvider>
    </AppContext.Provider>
  )
}
