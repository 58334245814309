import React from 'react'
import { ButtonProps, IconButton, lighten } from '@mui/material'
import ButtonBlock from 'components/blocks/ButtonBlock'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { useTranslation } from 'react-i18next'

export type ActionButtonBlockProps = {
  variant?: 'label' | 'icon'
  label?: string
  onClick: ButtonProps['onClick']
  component?: ButtonProps['component']
  href?: ButtonProps['href']
  loading?: boolean
  hidden?: boolean
}

export default function ActionButtonBlock(
  props: ActionButtonBlockProps,
): JSX.Element {
  const { t } = useTranslation()
  const {
    variant = 'label',
    label = t('actionButtonBlock.label'),
    component,
    href,
    onClick,
    loading,
    hidden,
  } = props

  if (hidden) {
    return null
  }

  return (
    <>
      {variant === 'label' && (
        <ButtonBlock<'a' | 'button'>
          type="button"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          component={component}
          href={href}
          loading={loading}
          onClick={onClick}
        >
          {label}
        </ButtonBlock>
      )}
      {variant === 'icon' && (
        <IconButton
          size="large"
          onClick={onClick}
          component={component}
          href={href}
          sx={{
            '&:hover': {
              backgroundColor: ({ palette }) =>
                lighten(palette.primary.main, 0.1),
            },
            alignSelf: 'center',
            backgroundColor: 'primary.main',
            color: ({ palette }) =>
              palette.getContrastText(palette.primary.main),
          }}
        >
          <ChevronRightRoundedIcon fontSize="large" />
        </IconButton>
      )}
    </>
  )
}
