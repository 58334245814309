import React, { ReactNode } from 'react'
import { ApolloError } from '@apollo/client'
import CustomErrorPage from 'components/boundaries/CustomErrorPage'
import { withTranslation } from 'react-i18next'

class ForbiddenErrorBoundary extends React.Component<{
  children: ReactNode
  t: (key: string) => string
}> {
  state = {
    hasError: false,
    message: this.props.t('forbiddenErrorBoundary.message'),
  }

  static getDerivedStateFromError(error: ApolloError) {
    if (
      error.graphQLErrors?.some(
        (error) => error.extensions?.code === 'FORBIDDEN',
      )
    ) {
      return {
        hasError: true,
        message: error.message,
      }
    }

    throw error // Propagate other errors to the parent boundary
  }

  render() {
    if (this.state.hasError) {
      return (
        <CustomErrorPage
          type="forbidden"
          message={this.state.message ?? ''}
        />
      )
    }
    return this.props.children
  }
}

export default withTranslation()(ForbiddenErrorBoundary)
