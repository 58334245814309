import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import AppBase from 'components/AppBase'
import Head from 'next/head'
import NestFronteggProvider from 'components/NestFronteggProvider'
import TrackerProvider from 'components/TrackerContext'
import { useRouter } from 'next/router'
import useUnsupportedBrowser, {
  UnsupportedBrowserMessage,
} from 'hooks/useUnsupportedBrowser'
import AppProvider from 'components/AppProvider'
import { MainApolloProvider } from 'components/ApolloClient'
import ErrorBoundaries from 'components/boundaries/ErrorBoundaries'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n'
import 'pages/global.css'

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const isUnsupportedBrowser = useUnsupportedBrowser()

  // Handle routing and deep linking
  useEffect(() => {
    if (router.isReady) {
      // Allow deep linking to work for initial page load.
      router.replace(router.asPath).catch((e) => {
        // Avoids Cancel rendering route errors.
        // Workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  if (isUnsupportedBrowser) {
    return <UnsupportedBrowserMessage />
  }

  return (
    <I18nextProvider i18n={i18n}>
      <NestFronteggProvider>
        <Head>
          <title>Nest</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
        </Head>
        <TrackerProvider>
          <MainApolloProvider>
            <ErrorBoundaries>
              <AppProvider>
                <AppBase>
                  <Component {...pageProps} />
                </AppBase>
              </AppProvider>
            </ErrorBoundaries>
          </MainApolloProvider>
        </TrackerProvider>
      </NestFronteggProvider>
    </I18nextProvider>
  )
}
