import React from 'react'
import { InputAdornment } from '@mui/material'
import {
  Field,
  FieldProps,
} from 'components/StoryPlayer/pages/PageForm'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import StoryFieldLabel from 'components/StoryFieldLabel'
import HelperBlocks from './HelperBlocks'
import { useTranslation } from 'react-i18next'

export interface TextField extends Field {
  type: 'text'
  placeholder?: string
  helperText?: string
  minLength?: number
  maxLength?: number
  multiline?: boolean
  minRows?: number
  maxRows?: number
  startAdornment?: string
  endAdornment?: string
  autoFocus?: boolean
}

interface StoryTextFieldProps extends TextField, FieldProps {
  disabled?: boolean
}

export default function StoryTextField(props: StoryTextFieldProps) {
  const {
    fieldState,
    label,
    required,
    startAdornment,
    endAdornment,
    helperBlocks,
    disableRequiredAsterisk,
    inputRef,
  } = props
  const { error } = fieldState
  const { t } = useTranslation()
  const helperText = error?.message || props.helperText

  return (
    <FormControl fullWidth error={!!error} sx={{ gap: 1 }}>
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      <OutlinedInput
        inputRef={inputRef}
        color="secondary"
        placeholder={
          props.placeholder ?? t('storyTextField.placeholder')
        }
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value ?? ''}
        autoFocus={props.autoFocus}
        multiline={props.multiline}
        minRows={props.minRows}
        maxRows={props.maxRows}
        disabled={props.disabled}
        required={required}
        fullWidth
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">
              {startAdornment}
            </InputAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">
              {endAdornment}
            </InputAdornment>
          )
        }
        error={!!error}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
