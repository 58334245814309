import React, { ReactNode } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import ProblemImage from 'assets/images/problem.svg'
import { useLoginActions } from '@frontegg/react'
import { useTranslation } from 'react-i18next'

export default function LoginAgain(props: { children: ReactNode }) {
  const { logout } = useLoginActions()
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        alignItems: 'center',
        color: 'text.primary',
        gap: 3,
        justifyContent: 'center',
        maxWidth: 480,
        minHeight: '70vh',
        mx: 'auto',
        my: 3,
        textAlign: 'center',
      }}
    >
      <Box sx={{ height: 200 }}>
        <ProblemImage />
      </Box>
      <Box>
        <Typography component="div" color="inherit" variant="h3">
          {t('loginAgain.somethingWentWrong')}
        </Typography>
        <Typography component="div" color="inherit" variant="body2">
          {props.children}
        </Typography>
      </Box>
      <Button onClick={() => logout()}>
        {t('loginAgain.logInAgain')}
      </Button>
    </Stack>
  )
}
