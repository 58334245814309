import React, { useCallback, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  BoxProps,
  CircularProgress,
  FormControl,
  Stack,
  ToggleButton,
  ToggleButtonProps,
  Typography,
  alpha,
  darken,
} from '@mui/material'
import {
  Field,
  FieldProps,
} from 'components/StoryPlayer/pages/PageForm'
import { MotionTrail } from 'components/MotionComponents'
import MarkdownContent from 'components/MarkdownContent'
import StoryFieldLabel from 'components/StoryFieldLabel'
import { isArray, isEqual } from 'lodash'
import { shouldClearSelectedValues } from 'utils/FormUtil'
import HelperBlocks from './HelperBlocks'
import {
  SelectOptionsQueryConfig,
  useSelectOptionsQuery,
} from 'hooks/useSelectOptionsQuery'
import { useTranslation } from 'react-i18next'

type CheckboxOptionValue = string | { [key: string]: unknown }

export type CheckboxField = Omit<Field, 'defaultValue'> & {
  type: 'checkbox'
  defaultValue?: CheckboxOptionValue[]
  value?: CheckboxOptionValue[]
} & (
    | {
        // Options hardcoded in the story with 'options'.
        options: {
          label: string
          value: CheckboxOptionValue
          disabled?: boolean
        }[]
        optionsQuery?: never
      }
    | {
        // Dynamic options via supported queries with 'optionsQuery'.
        options?: never
        optionsQuery: SelectOptionsQueryConfig
      }
  )

type StoryCheckboxFieldProps = Omit<FieldProps, 'value'> &
  CheckboxField & {
    disabled?: boolean
  }

export default function StoryCheckboxField(
  props: StoryCheckboxFieldProps,
) {
  const {
    onChange,
    defaultValue,
    label,
    required,
    helperBlocks,
    disableRequiredAsterisk,
    optionsQuery,
  } = props
  const [values, setValues] = useState(defaultValue ?? [])
  const { t } = useTranslation()
  const handleChange: ToggleButtonProps['onChange'] = useCallback(
    (e, value: CheckboxOptionValue) => {
      let result = values.some((v) => isEqual(v, value))
        ? values.filter((v) => !isEqual(v, value))
        : values.concat(value)

      // Clear values for mutually exclusive options (e.g. "none", "unknown"")
      if (shouldClearSelectedValues(value as string)) {
        result = [value]
      } else {
        result = result.filter(
          (v) => !shouldClearSelectedValues(v as string),
        )
      }

      setValues(result)
      onChange(result)
    },
    [onChange, values, setValues],
  )
  const { options: fetchedOptions, loading } =
    useSelectOptionsQuery(optionsQuery)
  const options = optionsQuery
    ? (fetchedOptions ?? [])
    : props.options
  const emptyOptions = !options.length && !loading

  useEffect(() => {
    if (isArray(props.value ?? [])) {
      setValues(props.value ?? [])
    } else {
      setValues([])
      console.error(
        `Invalid value for field name "${
          props.name
        }". Expected array, got ${typeof props.value}.`,
        props.value,
      )
    }
  }, [setValues, props.name, props.value])

  return (
    <FormControl
      fullWidth
      error={!!props.fieldState.error}
      sx={{ gap: 1 }}
    >
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      >
        <Typography variant="body2" sx={{ opacity: 0.6 }}>
          {t('storyCheckboxField.label')}
        </Typography>
      </StoryFieldLabel>
      <HelperBlocks blocks={helperBlocks} />
      {loading && (
        <Stack
          sx={{
            alignItems: 'center',
            height: 1,
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: 1,
            zIndex: 1,
          }}
        >
          <CircularProgress color="primary" size={32} />
        </Stack>
      )}
      {emptyOptions && (
        <Alert severity="error">
          {t('storyCheckboxField.noOptions')}
        </Alert>
      )}
      <MotionTrail
        id={props.name}
        items={options.map((button, index) => {
          const selected = values.some((v) =>
            isEqual(v, button.value),
          )
          const disabled = props.disabled || button.disabled

          return (
            <ToggleButton
              key={`${props.name}-${button.value}${index}`}
              type="button"
              name={props.name}
              value={button.value}
              fullWidth
              size="large"
              onBlur={props.onBlur}
              onChange={handleChange}
              selected={selected}
              disabled={disabled}
              sx={{
                '&.Mui-selected': {
                  '&.Mui-disabled': {
                    opacity: 0.66,
                  },
                  '&:hover': {
                    bgcolor: ({ palette }) =>
                      darken(palette.secondary.main, 0.1),
                  },
                  bgcolor: 'secondary.main',
                  color: 'secondary.contrastText',
                },
                '&:hover': {
                  bgcolor: ({ palette }) =>
                    darken(palette.background.default, 0.05),
                },
                bgcolor: 'background.default',
                color: 'inherit',
                gap: 1.5,
                justifyContent: 'flex-start',
                lineHeight: 1.4,
                minWidth: 120,
                textAlign: 'start',
              }}
            >
              <Checkmark
                checked={selected}
                sx={{ opacity: disabled ? 0.66 : 1 }}
              />
              <MarkdownContent value={button.label} />
            </ToggleButton>
          )
        })}
      />
    </FormControl>
  )
}

export function Checkmark(props: {
  checked: boolean
  sx?: BoxProps['sx']
}) {
  const { checked, sx } = props
  return (
    <Box
      component="svg"
      focusable="false"
      aria-hidden="true"
      viewBox="2 2 20 20"
      sx={{
        bgcolor: checked ? 'secondary.contrastText' : 'transparent',
        borderRadius: '2px',
        boxShadow: ({ palette }) =>
          `0 0 0 2px ${
            checked
              ? 'transparent'
              : alpha(palette.text.primary, 0.33)
          }`,
        color: ({ palette }) => darken(palette.secondary.main, 0.1),
        display: 'block',
        height: 18,
        m: '1px',
        minWidth: 18,
        pointerEvents: 'none',
        strokeDasharray: 20,
        strokeDashoffset: checked ? 0 : 20,
        transition: 'stroke-dashoffset .2s ease',
        width: 18,
        ...sx,
      }}
      fill="none"
    >
      <path
        d="M6.4 12.0L10.0 15.6L17.6 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  )
}
