import React, { useEffect, useState } from 'react'
import useDialog from 'hooks/useDialog'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import {
  Alert,
  AlertColor,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import MarkdownContent from 'components/MarkdownContent'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  formatDateTime,
  getCurrentIsoDate,
} from '@shared/utils/DateUtil'
import ErrorTooltip from 'components/ErrorTooltip'
import SignatureField from 'components/SignatureField'
import useConsentHashParams from 'hooks/useConsentHashParams'
import { omit } from 'lodash'
import { useAuth } from '@frontegg/react'
import {
  useCompletePatientConsentMutation,
  usePatientConsentLazyQuery,
} from '__generated__/graphql'
import LoadingBackdrop from './LoadingBackdrop'
import ConsentCompleteDialog, {
  ConsentCompleteDialogSettings,
} from './ConsentCompleteDialog'
import { useRouter } from 'next/router'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { useTranslation } from 'react-i18next'

const formId = 'consent-form'

type ConsentDialogSettings = {
  postAgreeAction: {
    url: string
    target?: '_self' | '_blank'
  }
}

type ConsentSettings = {
  consentCompleteDialog: ConsentCompleteDialogSettings
  consentDialog: ConsentDialogSettings
}

type ConsentConfig = {
  id: string
  name: string
  content: string
  statements?: {
    id: string
    name: string
    label: string
    required?: boolean
  }[]
}

type SnackBarData = {
  message: string
  severity: AlertColor
}

export type ConsentFormValues = {
  completedAt: string
  consent: boolean
  signature: string
  [key: string]: unknown
}

export const getConsentIdFromPath = (url: string) => {
  const matches = url?.match(/#\/consent\/?([\w-]+)\/?/)
  return matches?.[1]
}

export const getConsentUrl = (consentId: string) =>
  `#/consent/${consentId}`

const getCheckedStatementIds = (
  statements: ConsentConfig['statements'],
  formValues: ConsentFormValues,
) =>
  statements
    .filter((statement) => !!formValues[statement.name])
    .map(({ id }) => id)

const defaultValues = {
  completedAt: '',
  consent: false,
  signature: '',
}

export default function ConsentDialog(): JSX.Element {
  const { t } = useTranslation()
  const router = useRouter()
  const [consentDialogProps, { setOpen: setConsentDialogOpen }] =
    useDialog('consentDialog')
  const formMethods = useForm<
    ConsentFormValues,
    unknown,
    ConsentFormValues
  >({ defaultValues })
  const { control, handleSubmit, clearErrors, reset } = formMethods
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackBarData, setSnackBarData] = useState<SnackBarData>({
    message: '',
    severity: 'success',
  })
  const handleConsentDialogClose = () => {
    window.location.hash = ''
    consentDialogProps.onClose()
    clearErrors()
  }
  const { isAuthenticated } = useAuth()
  const { consentId, completed } = useConsentHashParams({
    // Require authenticated user to enable consent hash params.
    disabled: !isAuthenticated,
  })
  const [fetchPatientConsent, { data, loading: consentLoading }] =
    usePatientConsentLazyQuery({
      onCompleted(data) {
        const { patientConsent } = data.patientApp.loggedInPatient
        const statements: Record<string, boolean> =
          patientConsent.statements.reduce((acc, statement) => {
            acc[statement.name] = statement.checked
            return acc
          }, {})

        reset({
          completedAt: patientConsent.completedAt,
          consent: !!patientConsent.completedAt,
          signature: patientConsent.signature,
          ...statements,
        })
      },
      onError(error) {
        setSnackBarData({
          message: error.message,
          severity: 'error',
        })
        handleConsentDialogClose()
        setSnackbarOpen(true)
      },
    })
  const {
    id: patientConsentId,
    name,
    content,
    statements,
    completedAt,
    settings,
    primaryStatement,
  } = data?.patientApp?.loggedInPatient?.patientConsent ?? {}
  const {
    consentCompleteDialog: consentCompleteDialogSettings,
    consentDialog: consentDialogSettings,
  } = (settings as ConsentSettings) ?? {}

  const isConsentComplete = !!completedAt
  const [
    completePatientConsentMutation,
    { loading: completeConsentLoading },
  ] = useCompletePatientConsentMutation()
  const interpolate = useInterpolate(StoryPlayerContext)

  // Reset form and fetch consent data on dialog open.
  useEffect(() => {
    if (consentId) {
      reset(defaultValues)
      fetchPatientConsent({ variables: { consentId } })
    }
    setConsentDialogOpen(!!consentId && !completed)
  }, [
    consentId,
    setConsentDialogOpen,
    fetchPatientConsent,
    reset,
    completed,
  ])

  return (
    <>
      <LoadingBackdrop
        open={consentLoading || completeConsentLoading}
      />
      {!consentLoading && (
        <EnhancedDialog
          {...consentDialogProps}
          fullWidth
          maxWidth="sm"
          scroll="body"
          disableCloseOnBackdropClick
          onClose={handleConsentDialogClose}
          sx={{
            '& .MuiFormControlLabel-asterisk': {
              // Hide asterisk and use inline asterisk instead.
              display: 'none',
            },
            '& .MuiFormControlLabel-label': {
              '& a': {
                color: 'primary.main',
              },
              typography: 'body2',
            },
          }}
        >
          <EnhancedDialogTitle onClose={handleConsentDialogClose}>
            {name}
            {!!completedAt && (
              <Typography variant="body2" color="text.secondary">
                {formatDateTime(completedAt, true)}
              </Typography>
            )}
          </EnhancedDialogTitle>
          {!isConsentComplete && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ px: 3 }}
            >
              {t('consentDialog.scrollToReadConsent')}
            </Typography>
          )}
          <FormProvider {...formMethods}>
            <Stack
              component="form"
              id={formId}
              onSubmit={handleSubmit(async (form) => {
                clearErrors()
                const { data } = await completePatientConsentMutation(
                  {
                    variables: {
                      completePatientConsentInput: {
                        id: patientConsentId,
                        signature: form.signature,
                        statements: getCheckedStatementIds(
                          statements,
                          form,
                        ),
                      },
                    },
                  },
                )

                if (data.patientAppCompletePatientConsent) {
                  handleConsentDialogClose()

                  if (consentDialogSettings?.postAgreeAction) {
                    const { target = '_self' } =
                      consentDialogSettings.postAgreeAction
                    const url = interpolate(
                      consentDialogSettings.postAgreeAction.url,
                    )
                    if (url) {
                      if (target === '_self') {
                        // Handle internal links.
                        router.push(url)
                      } else {
                        // Handle external links.
                        window.open(url, target)
                      }
                    }
                  } else if (consentCompleteDialogSettings) {
                    router.replace(
                      `${getConsentUrl(consentId)}/completed`,
                    )
                  }
                }
              })}
            >
              <Paper
                square
                elevation={2}
                sx={{
                  borderColor: 'divider',
                  mx: 3,
                }}
              >
                <MarkdownContent
                  value={content}
                  sx={{
                    '& > *:first-of-type': {
                      mt: 0,
                    },
                    maxHeight: 200,
                    overflowY: 'auto',
                    p: 2,
                    typography: 'body2',
                  }}
                />
                <Divider />
                <Controller
                  name="consent"
                  control={control}
                  rules={{ required: t('consentDialog.required') }}
                  defaultValue={false}
                  render={({ field, fieldState }) => (
                    <FormControl
                      error={!!fieldState.error}
                      sx={{
                        bgcolor: ({ palette }) =>
                          palette.action.hover,
                        color: fieldState.error
                          ? 'error.main'
                          : 'text.primary',
                        pb: 1,
                        pt: 1.5,
                        px: 2,
                        width: 1,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <ErrorTooltip
                            title={fieldState.error?.message ?? ''}
                            open={!!fieldState.error}
                            placement="bottom-start"
                            arrow
                          >
                            <Checkbox
                              {...field}
                              inputRef={field.ref}
                              checked={!!field.value}
                              disabled={isConsentComplete}
                            />
                          </ErrorTooltip>
                        }
                        label={
                          <MarkdownContent
                            value={
                              primaryStatement
                                ? primaryStatement + '*'
                                : t(
                                    'consentDialog.iUnderstandAndAgree',
                                    { name },
                                  ) + '*'
                            }
                          />
                        }
                      />
                    </FormControl>
                  )}
                />
              </Paper>
              <DialogContent>
                <Stack sx={{ gap: 2, pb: 3, px: 2 }}>
                  {(statements ?? []).map(({ required, ...item }) => (
                    <Controller
                      key={item.name}
                      name={item.name}
                      control={control}
                      rules={{
                        required:
                          required && t('consentDialog.required'),
                      }}
                      defaultValue={false}
                      render={({ field, fieldState }) => (
                        <FormControl
                          error={!!fieldState.error}
                          sx={{
                            color: fieldState.error
                              ? 'error.main'
                              : 'text.primary',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <ErrorTooltip
                                title={
                                  fieldState.error?.message ?? ''
                                }
                                open={!!fieldState.error}
                                placement="bottom-start"
                                arrow
                              >
                                <Checkbox
                                  ref={field.ref}
                                  checked={!!field.value}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  disabled={isConsentComplete}
                                />
                              </ErrorTooltip>
                            }
                            label={
                              <MarkdownContent
                                value={
                                  item.label + (required ? '*' : '')
                                }
                              />
                            }
                          />
                        </FormControl>
                      )}
                    />
                  ))}
                </Stack>
                <Stack>
                  <Controller
                    name="signature"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <SignatureField
                        {...omit(field, ['ref'])}
                        inputRef={field.ref}
                        value={field.value ?? ''}
                        error={!!fieldState.error}
                        disabled={isConsentComplete}
                        helperText={
                          <>
                            {formatDateTime(
                              // Default to current date if consent hasn't been completed yet.
                              completedAt ?? getCurrentIsoDate(),
                              false,
                              'MM/dd/yyyy',
                            )}
                          </>
                        }
                      />
                    )}
                  />
                </Stack>
              </DialogContent>
            </Stack>
          </FormProvider>
          {!isConsentComplete && (
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                form={formId}
                disabled={completeConsentLoading}
              >
                {t('consentDialog.iAgree')}
              </Button>
            </DialogActions>
          )}
        </EnhancedDialog>
      )}
      <ConsentCompleteDialog
        onClose={() => router.push('#')}
        open={!!completed}
        {...consentCompleteDialogSettings}
      />
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        autoHideDuration={6000}
        onClose={(e, reason) => {
          if (reason === 'timeout') {
            setSnackbarOpen(false)
          }
        }}
      >
        <Alert severity={snackBarData.severity}>
          {snackBarData.message}
        </Alert>
      </Snackbar>
    </>
  )
}
