import i18n from 'i18n'
import { ValidationValueMessage } from 'react-hook-form'
import { SelectOption } from 'types/Global'

export type ValidationRulePattern = {
  message: string
  value: RegExp
}

export const RulePattern: {
  email: ValidationValueMessage<RegExp>
  tel: ValidationValueMessage<RegExp>
  telOptionalCountryCode: ValidationValueMessage<RegExp>
  date: ValidationValueMessage<RegExp>
} = {
  get date() {
    return {
      message: i18n.t('formUtil.date.message'),
      // Input collected as MM/DD/YYYY using mask input
      // but validated as YYYY-MM-DD for storage.
      value: /^\d{4}-\d{2}-\d{2}$/,
    }
  },
  get email() {
    return {
      message: i18n.t('formUtil.email.message'),
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    }
  },
  get tel() {
    return {
      message: i18n.t('formUtil.tel.message'),
      value: /^\+1[0-9]{10}$/,
    }
  },
  get telOptionalCountryCode() {
    return {
      message: i18n.t('formUtil.telOptionalCountryCode.message'),
      value: /^(\+1)?[0-9]{10}$/,
    }
  },
}

export function shouldClearSelectedValues(value: string) {
  return ['none', 'unknown', 'NONE', 'UNKNOWN'].includes(value)
}

export function getOptionsFromObject(labels: {
  [key: string]: string
}): SelectOption[] {
  return Object.entries(labels).map(([value, label]) => ({
    label,
    value,
  }))
}

export function scrollToError() {
  setTimeout(() => {
    const errorInput = document.querySelector(
      '.Mui-error',
    ) as HTMLInputElement
    errorInput?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'center',
    })
  })
}

/**
 * Checks if value is a finite primitive number.
 * @param value number or string to check
 * @returns Returns `true` if value is a finite number, else `false`.
 */
export function isValidNumber(value: number | string) {
  return (!!value || value === 0) && isFinite(Number(value))
}

/**
 * Extracts a finite number from a string.
 * @param value string to extract number
 * @returns Returns number as string
 * @example
 * parseNumber('123.45') // '123.45'
 * parseNumber('123.') // '123.'
 * parseNumber('abc123e') // '123'
 */
export function parseNumberString(value: string) {
  return value.replace(/[^$0-9.]/g, '')
}
