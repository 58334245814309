import { ChipProps } from '@mui/material'
import { TodoListItem } from 'components/TodoList'
import { TimelineEntryProps } from 'components/TimelineEntry'
import { StepPriority } from 'models/GuidelineStatement'
import { formatDateTime } from '@shared/utils/DateUtil'
import { formatTel } from '@shared/utils/TelUtil'
import {
  GeneVariantClassification,
  PatientAppPatient,
  ProviderSpecialty,
  StepType,
} from '__generated__/graphql'
import CareTeamMemberModel from './CareTeamMember'
import i18n from 'i18n'

export enum SpecialtyLabels {
  INTERNAL_MEDICINE = 'internalMedicine',
  FAMILY_MEDICINE = 'familyMedicine',
  OBSTETRICS_GYNECOLOGY = 'obstetricsAndGynecology',
  ONCOLOGY = 'oncology',
  CARDIOLOGY = 'cardiology',
  GASTROENTEROLOGY = 'gastroenterology',
  SURGERY = 'surgery',
  DERMATOLOGY = 'dermatology',
  PSYCHIATRY = 'psychiatry',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export function getSpecialtyLabel(
  value: string | ProviderSpecialty,
): string {
  return i18n.t(`patient.specialty.${SpecialtyLabels[value]}`)
}

export function getSpecialtyOptions() {
  return Object.keys(ProviderSpecialty).map((value) => ({
    label: getSpecialtyLabel(value),
    value,
  }))
}

export enum Relationship {
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  GRANDMOTHER = 'GRANDMOTHER',
  GRANDFATHER = 'GRANDFATHER',
  SISTER = 'SISTER',
  BROTHER = 'BROTHER',
  HALF_SISTER = 'HALF_SISTER',
  HALF_BROTHER = 'HALF_BROTHER',
  AUNT = 'AUNT',
  UNCLE = 'UNCLE',
  NIECE = 'NIECE',
  NEPHEW = 'NEPHEW',
  COUSIN_FEMALE = 'COUSIN_FEMALE',
  COUSIN_MALE = 'COUSIN_MALE',
  GRANDCHILD_FEMALE = 'GRANDCHILD_FEMALE',
  GRANDCHILD_MALE = 'GRANDCHILD_MALE',
  OTHER = 'OTHER',
}

export enum RelationshipLabels {
  MOTHER = 'mother',
  FATHER = 'father',
  GRANDMOTHER = 'grandmother',
  GRANDFATHER = 'grandfather',
  SISTER = 'sister',
  BROTHER = 'brother',
  HALF_SISTER = 'halfSister',
  HALF_BROTHER = 'halfBrother',
  AUNT = 'aunt',
  UNCLE = 'uncle',
  NIECE = 'niece',
  NEPHEW = 'nephew',
  COUSIN_FEMALE = 'cousinFemale',
  COUSIN_MALE = 'cousinMale',
  GRANDCHILD_FEMALE = 'grandchildFemale',
  GRANDCHILD_MALE = 'grandchildMale',
  OTHER = 'other',
}

export function getRelationshipLabel(
  value: string | Relationship | undefined,
): string {
  if (!value) {
    return ''
  }
  return i18n.t(`patient.relationship.${RelationshipLabels[value]}`)
}

export function getRelationshipOptions() {
  return Object.keys(Relationship).map((value) => ({
    label: getRelationshipLabel(value),
    value,
  }))
}

export enum CareStepCategory {
  MEDICAL_CARE = 'MEDICAL_CARE',
  SELF_CARE = 'SELF_CARE',
  OPTIONS_TO_CONSIDER = 'OPTIONS_TO_CONSIDER',
}

export type PatientCareStep = {
  title: string
  category: keyof typeof CareStepCategory
  priority: keyof typeof StepPriority
  type: keyof typeof StepType
  description?: string
  patientDescription?: string
  areaOfConcern: string
  id?: string
  metadata?: {
    careStepStories?: { slug: string; label?: string }[]
  }
  subtitle?: string
  storyId?: string
  chip?: {
    label?: ChipProps['label']
    icon?: ChipProps['icon']
    color?: ChipProps['color']
  }
}

export enum GeneVariantClassificationLabels {
  PATHOGENIC = 'pathogenic',
  LIKELY_PATHOGENIC = 'likelyPathogenic',
  VUS = 'vus',
}

export function getGeneVariantClassificationLabel(
  value:
    | string
    | GeneVariantClassificationLabels
    | GeneVariantClassification,
): string {
  return i18n.t(
    `patient.geneVariantClassification.${GeneVariantClassificationLabels[value]}`,
  )
}

export enum BiologicalSexLabels {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export function getBiologicalSexLabel(
  value: string | BiologicalSexLabels,
): string {
  return i18n.t(`patient.biologicalSex.${BiologicalSexLabels[value]}`)
}

export type PatientMocks = {
  todos: TodoListItem[]
  timeline: TimelineEntryProps[]
}

export default class PatientModel
  extends PatientAppPatient
  implements PatientMocks
{
  todos: TodoListItem[]
  timeline: TimelineEntryProps[]
  careTeam: CareTeamMemberModel[]

  constructor(
    data: Omit<
      PatientAppPatient,
      'patientConsent' | 'patientConsents'
    >,
    mocks: PatientMocks,
  ) {
    super()
    Object.assign(this, data, mocks, {
      careTeam:
        data.careTeamMembers?.map(
          (careTeamMember) => new CareTeamMemberModel(careTeamMember),
        ) ?? [],
    })
  }

  get language() {
    const { lang } = this
    if (!lang) return 'en'
    return lang.includes('-') ? lang.split('-')[0] : lang
  }

  get fullName() {
    const { firstName, lastName } = this
    return `${firstName} ${lastName}`
  }

  get providerName() {
    return null
  }

  get formattedAddress() {
    if (!this.address) {
      return null
    }

    const { line1, line2, city, state, postalCode } = this.address

    return [
      [line1, line2].filter(Boolean).join(' '),
      `${city}, ${state} ${postalCode}`,
    ].join('\n')
  }

  get formattedBirthDate() {
    return formatDateTime(this.birthDate, false)
  }

  get formattedPhone() {
    return formatTel(this.phone)
  }

  get formattedBiologicalSex() {
    return getBiologicalSexLabel(this.biologicalSex)
  }

  get formattedInsurance() {
    if (!this.insurance) {
      return () => null
    }

    return [
      this.insurance.carrierName,
      `${i18n.t('patient.insurance.id')}: ${this.insurance.memberId}`,
      `${i18n.t('patient.insurance.group')}: ${this.insurance.groupNumber}`,
    ].join('\n')
  }
}
