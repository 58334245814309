import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { Howl } from 'howler'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import GuidedTour, { Step } from 'components/GuidedTour'
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded'
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded'
import DeterminateCircularProgress from 'components/DeterminateCircularProgress'
import { Box, BoxProps, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

export const GuideTourStoryPlayerAudioKey = 'storyPlayer.audio'

type StoryAudioButtonProps = {
  audioSrc: string
  color: string
  onClick: IconButtonProps['onClick']
}

export default function StoryAudioButton(
  props: StoryAudioButtonProps,
): JSX.Element {
  const { audioSrc, color } = props
  const { t } = useTranslation()
  const {
    enableAudio,
    setEnableAudio,
    storyPlayerOpen,
    storyAudioGuideOpen,
    storyAudioGuideCompleted,
    setStoryAudioGuideOpen,
    setStoryAudioGuideCompleted,
  } = useContext(StoryPlayerContext)
  const [howl, setHowl] = useState<Howl>(null)
  const [audioEnded, setAudioEnded] = useState(false)
  const tourGuideSteps: Step[] = [
    {
      content: t('storyAudioButton.topGuideStepContent'),
      disableBeacon: true,
      disableScrolling: true,
      placement: 'right',
      styles: {
        spotlight: {
          borderRadius: 100,
        },
      },
      target: '.storyAudioButton',
    },
  ]

  // Load/unload audio track.
  useEffect(() => {
    if (audioSrc) {
      setHowl(
        new Howl({ src: [audioSrc] })
          .on('play', () => setAudioEnded(false))
          .on('end', () => setAudioEnded(true)),
      )
    } else {
      howl?.unload()
      setHowl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSrc])

  // Toggle audio playback.
  useEffect(() => {
    if (enableAudio) {
      howl?.play()
    } else {
      howl?.pause()
    }
    return () => {
      howl?.stop()
    }
  }, [howl, enableAudio])

  // Display logic for audio guide.
  useEffect(() => {
    setStoryAudioGuideOpen(
      !storyAudioGuideCompleted && storyPlayerOpen && !!audioSrc,
    )
  }, [
    setStoryAudioGuideOpen,
    storyPlayerOpen,
    storyAudioGuideCompleted,
    audioSrc,
  ])

  return (
    <>
      <Tooltip
        title={
          storyAudioGuideOpen || isMobile
            ? ''
            : enableAudio
              ? 'Stop Listening'
              : 'Start Listening'
        }
      >
        <Box className="storyAudioButton">
          <AudioToggleButton
            variant="sound-wave"
            onClick={() => setEnableAudio(!enableAudio)}
            sx={{ color }}
            enabled={enableAudio}
          >
            <DeterminateCircularProgress
              getProgress={() => {
                if (!howl) {
                  return 0
                }
                if (audioEnded) {
                  return 1
                }
                const elapsedTime = howl.seek()
                const duration = howl.duration()
                return elapsedTime / duration
              }}
              enabled={enableAudio && !!audioSrc}
              enableTransition={audioEnded}
            />
          </AudioToggleButton>
        </Box>
      </Tooltip>
      <GuidedTour
        id={GuideTourStoryPlayerAudioKey}
        steps={tourGuideSteps}
        open={storyAudioGuideOpen}
        onComplete={() => setStoryAudioGuideCompleted(true)}
      />
    </>
  )
}

export type AudioToggleButtonProps = Pick<
  IconButtonProps,
  'sx' | 'onClick'
> & {
  variant?: 'mute-toggle' | 'sound-wave'
  enabled: boolean
  children?: ReactNode
}

export function AudioToggleButton(props: AudioToggleButtonProps) {
  const {
    children,
    variant = 'mute-toggle',
    enabled,
    onClick,
    sx,
  } = props

  return (
    <IconButton
      onClick={onClick}
      sx={{
        '&:hover': {
          bgcolor: 'rgba(255,255,255,.5)',
        },
        backdropFilter: 'blur(10px)',
        bgcolor: 'rgba(255,255,255,.33)',
        boxShadow:
          variant === 'sound-wave' &&
          enabled &&
          `0 0 0 2px rgba(0,0,0,.05) inset`,
        height: 40,
        width: 40,
        ...sx,
      }}
    >
      {children}
      {variant === 'mute-toggle' &&
        (enabled ? (
          <VolumeUpRoundedIcon color="inherit" />
        ) : (
          <VolumeOffRoundedIcon color="inherit" />
        ))}
      {variant === 'sound-wave' &&
        (enabled ? (
          <SoundWaveIcon color="inherit" />
        ) : (
          <SoundWaveIcon color="inherit" />
        ))}
    </IconButton>
  )
}

export function SoundWaveIcon(
  props: BoxProps<'svg'> & { color?: string },
) {
  const { color = 'inherit', ...boxProps } = props
  return (
    <Box
      component="svg"
      width="19.3065"
      height="21.1333"
      viewBox="-2.5 -1.5 24 24"
      {...boxProps}
      sx={{ color, height: 24, width: 24, ...boxProps.sx }}
    >
      <g>
        <rect
          height="21.1333"
          opacity="0"
          width="19.3065"
          x="0"
          y="0"
        />
        <path
          d="M18.0144 14.2515C18.5315 14.2515 18.9452 13.8409 18.9452 13.3305L18.9452 7.79967C18.9452 7.28924 18.5315 6.86891 18.0144 6.86891C17.4938 6.86891 17.0965 7.28924 17.0965 7.79967L17.0965 13.3305C17.0965 13.8409 17.4938 14.2515 18.0144 14.2515Z"
          fill="currentColor"
          fillOpacity="0.85"
        />
        <path
          d="M14.5997 18.9839C15.1167 18.9839 15.5238 18.5734 15.5238 18.0629L15.5238 3.07033C15.5238 2.5599 15.1167 2.13957 14.5997 2.13957C14.0724 2.13957 13.6751 2.5599 13.6751 3.07033L13.6751 18.0629C13.6751 18.5734 14.0724 18.9839 14.5997 18.9839Z"
          fill="currentColor"
          fillOpacity="0.85"
        />
        <path
          d="M11.1751 16.1119C11.6989 16.1119 12.1059 15.708 12.1059 15.1909L12.1059 5.93926C12.1059 5.42217 11.6989 5.0085 11.1751 5.0085C10.6545 5.0085 10.2572 5.42217 10.2572 5.93926L10.2572 15.1909C10.2572 15.708 10.6545 16.1119 11.1751 16.1119Z"
          fill="currentColor"
          fillOpacity="0.85"
        />
        <path
          d="M7.75056 21.1302C8.27431 21.1302 8.68798 20.7196 8.68798 20.2025L8.68798 0.930759C8.68798 0.413671 8.27431 0 7.75056 0C7.23657 0 6.83933 0.413671 6.83933 0.930759L6.83933 20.2025C6.83933 20.7196 7.23657 21.1302 7.75056 21.1302Z"
          fill="currentColor"
          fillOpacity="0.85"
        />
        <path
          d="M4.33267 17.4368C4.85642 17.4368 5.26653 17.0263 5.26653 16.5092L5.26653 4.62098C5.26653 4.10389 4.85642 3.68356 4.33267 3.68356C3.82179 3.68356 3.41789 4.10389 3.41789 4.62098L3.41789 16.5092C3.41789 17.0263 3.82179 17.4368 4.33267 17.4368Z"
          fill="currentColor"
          fillOpacity="0.85"
        />
        <path
          d="M0.911227 13.5421C1.44164 13.5421 1.84865 13.1284 1.84865 12.6144L1.84865 8.51575C1.84865 7.99866 1.44164 7.57833 0.911227 7.57833C0.397245 7.57833 0 7.99866 0 8.51575L0 12.6144C0 13.1284 0.397245 13.5421 0.911227 13.5421Z"
          fill="currentColor"
          fillOpacity="0.85"
        />
      </g>
    </Box>
  )
}
