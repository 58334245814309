import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import SettingsStore from 'stores/SettingsStore'
import useStoryHashParams from 'hooks/useStoryHashParams'
import useStoryProgress from 'hooks/useStoryProgress'
import {
  StoryPlayerContext,
  StoryPlayerContextProps,
} from 'components/StoryPlayerContext'
import { isMobile } from 'react-device-detect'
import { GuideTourStoryPlayerAudioKey } from 'components/StoryAudioButton'
import PatientModel from 'models/Patient'
import { PatientAppPatientData } from '__generated__/graphql'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { flattenQuestionnaireResponse } from 'components/StoryPlayerUtil'
import { StoryData } from 'components/StoryPlayer'

export default function StoryPlayerProvider(props: {
  children: ReactNode
  storyPlayerOpen?: boolean
  isAuthenticated?: boolean
  patient: PatientModel
  patientData: PatientAppPatientData[]
  careStepOccurrences: StoryPlayerContextProps['careStepOccurrences']
  storyId?: string
  pageId?: string
  refetchPatientData: StoryPlayerContextProps['refetchPatientData']
}) {
  const {
    patient,
    patientData,
    careStepOccurrences,
    isAuthenticated,
    refetchPatientData,
  } = props
  const [storyPlayerProgress, setStoryPlayerProgress] = useState(0)
  const [enableAudio, setEnableAudio] = useState(false)
  const [storyPlayerMuted, setStoryPlayerMuted] = useState(isMobile)
  const [storyPlayerOpen, setStoryPlayerOpen] = useState(
    props.storyPlayerOpen,
  )
  const [storyAudioGuideOpen, setStoryAudioGuideOpen] =
    useState(false)
  const [storyAudioGuideCompleted, setStoryAudioGuideCompleted] =
    useState(false)
  const [questionnaireResponses, setQuestionnaireResponses] =
    useState<StoryPlayerContextProps['questionnaireResponses']>({})
  const [questionnaireResponseItems, setQuestionnaireResponseItems] =
    useState<StoryPlayerContextProps['questionnaireResponseItems']>(
      [],
    )
  const handleSetQuestionnaireResponseItems = useCallback(
    (
      items: StoryPlayerContextProps['questionnaireResponseItems'],
    ) => {
      setQuestionnaireResponseItems(items)
      setQuestionnaireResponses(flattenQuestionnaireResponse(items))
    },
    [],
  )
  const [hiddenControls, setHiddenControls] = useState(false)
  const [story, setStory] = useState<StoryData>(null)
  const [storyPages, setStoryPages] = useState<StoryPageData[]>([])
  const [templatePages, setTemplatePages] = useState<StoryPageData[]>(
    [],
  )
  const storyProgress = useStoryProgress()
  const storyHashParams = useStoryHashParams({
    // Require authenticated user enable story hash params.
    disabled: !isAuthenticated,
  })
  const storyId = props.storyId || storyHashParams.storyId
  const pageId = props.pageId || storyHashParams.pageId
  const page = storyPages.find(({ id }) => id === pageId)

  // Handle story player open state.
  useEffect(() => {
    setStoryPlayerOpen(!!props.storyPlayerOpen)
  }, [props.storyPlayerOpen])

  // When mounted, set `storyAudioGuideCompleted` based on settings store.
  useEffect(() => {
    const guidedToursCompleted = SettingsStore.get(
      'settings.guidedToursCompleted',
    )
    setStoryAudioGuideCompleted(
      !!guidedToursCompleted?.[GuideTourStoryPlayerAudioKey],
    )
  }, [])

  return (
    <StoryPlayerContext.Provider
      value={{
        careStepOccurrences,
        enableAudio,
        hiddenControls,
        page,
        pageId,
        patient,
        patientData,
        questionnaireResponseItems,
        questionnaireResponses,
        refetchPatientData,
        setEnableAudio,
        setHiddenControls,
        setQuestionnaireResponseItems:
          handleSetQuestionnaireResponseItems,
        setStory,
        setStoryAudioGuideCompleted,
        setStoryAudioGuideOpen,
        setStoryPages,
        setStoryPlayerMuted,
        setStoryPlayerOpen,
        setStoryPlayerProgress,
        setTemplatePages,
        story,
        storyAudioGuideCompleted,
        storyAudioGuideOpen,
        storyId,
        storyPages,
        storyPlayerMuted,
        storyPlayerOpen,
        storyPlayerProgress,
        storyProgress,
        templatePages,
      }}
    >
      {props.children}
    </StoryPlayerContext.Provider>
  )
}
