import {
  OrderableTestDisqualifyingQuestionsSchemasQuery,
  SelectOption,
} from '__generated__/graphql'
import { Section } from '@shared/FormSchema'
import { OrderConfig } from 'components/StoryPlayer/Modules/Order'
import { PageTransformer } from 'components/StoryPlayer/pageTransformer'
import { getFormPagesFromSections } from 'components/StoryPlayer/pageTransformer/pageTransformerUtil'
import { generateId } from 'utils/SeedUtil'

const getPageId = (value: string) =>
  generateId('testQualificationQuestions' + value)

const testQualificationQuestions: PageTransformer<{
  contactPhoneNumber: string
  currency: OrderConfig['currency']
  labId: string
  orderPaymentMethod: OrderConfig['orderPaymentMethod']
  orderableTestOptions: SelectOption[]
  selectedTestIds: string[]
  testSelectionPageId: string
}> = ({ returnToPageId, data, options }) => {
  const schemaResults =
    (data as OrderableTestDisqualifyingQuestionsSchemasQuery)
      ?.patientApp?.orderableTestDisqualifyingQuestionsSchemas ?? []
  const sections = schemaResults
    .map(({ schema }) => schema.sections as Section[])
    .flat()
  const {
    contactPhoneNumber,
    currency,
    labId,
    orderPaymentMethod,
    orderableTestOptions = [],
    selectedTestIds = [],
    testSelectionPageId,
  } = options
  const formPages = getFormPagesFromSections({
    getPageId,
    returnToPageId,
    sections,
  })
    // Add page connections linearly.
    .map((page, index, pages) => {
      const nextPage = pages[index + 1]
      if (nextPage?.id) {
        page.defaultConnectionPageId = nextPage.id
      } else {
        page.defaultConnectionPageId = returnToPageId
        page.connectionRules = [
          {
            conditions: {
              all: [],
            },
            event: {
              params: {
                options: {
                  contactPhoneNumber,
                  currency,
                  labId,
                  orderPaymentMethod,
                  orderableTestOptions,
                  schemaResults,
                  testSelectionPageId,
                },
                pageTransformer: 'testQualificationSummary',
                returnToPageId: returnToPageId,
              },
              type: 'DynamicChapterNavigation',
            },
          },
        ]
      }

      return page
    })

  // Page for handling case for no qualifying questions.
  if (!formPages.length) {
    const selectedTestNames = orderableTestOptions
      .filter(({ value }) => selectedTestIds.includes(value))
      .map(({ label }) => label)
    return [
      {
        blocks: [
          {
            content: `Test Qualification Summary`,
            type: 'text',
            variant: 'h4',
          },
          {
            content: `There are no qualifying questions for the tests.`,
            type: 'text',
          },
          {
            content: selectedTestNames
              .map((testName) => `- ${testName} \n`)
              .join(''),
            type: 'text',
          },
          {
            content: `*Let's continue to the next step.*`,
            type: 'text',
          },
        ],
        defaultConnectionPageId: returnToPageId,
        enableActionIconButton: true,
        id: getPageId('noQualifyingQuestions'),
        type: 'custom',
      },
    ]
  }
  return formPages
}

export default testQualificationQuestions
