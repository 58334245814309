import { CareStepOccurrencesQuery } from '__generated__/graphql'
import { getFrequencyLabel } from 'components/FrequencyField'
import { isPast, isSameDay } from 'date-fns'
import { isDate, isString } from 'lodash'

type CareStepOccurrence =
  CareStepOccurrencesQuery['patientApp']['careStepOccurrences'][0]

export default class CareStepOccurrenceModel
  implements CareStepOccurrence
{
  // Inherited properties.
  careStepCodeId: CareStepOccurrence['careStepCodeId']
  lastPerformedAt: CareStepOccurrence['lastPerformedAt']
  careStep?: CareStepOccurrence['careStep']
  history: CareStepOccurrence['history'] = []
  // Extended properties.
  isCareStepDue = false
  frequencyLabel = ''

  constructor(data: CareStepOccurrence) {
    Object.assign(this, data)
    this.careStep = data.careStep ?? {
      data: {},
      dueAt: null,
      name: '',
      patientDescription: '',
      type: null,
    }
    this.isCareStepDue = this.checkCareStepDue(this.careStep.dueAt)
    this.frequencyLabel = getFrequencyLabel(
      this.careStep.data?.frequency,
    )
  }

  /**
   * Check to see if the care step is due per the care step due date.
   * Additionally check if last performed date is before the care step due date otherwise skip.
   */
  checkCareStepDue(dueAt: string | Date) {
    dueAt = isDate(dueAt) ? dueAt : dueAt
    dueAt = isString(dueAt) ? new Date(dueAt) : null

    if (!dueAt) {
      return false
    }
    return isPast(dueAt) || isSameDay(dueAt, new Date())
  }
}
