import {
  PatientAppOrderableTestDisqualifyingQuestionsSchemaResult,
  SelectOption,
} from '__generated__/graphql'
import { FormSchema } from '@shared/FormSchema'
import { formatTel } from '@shared/utils/TelUtil'
import {
  OrderConfig,
  OrderModule,
  OrderModulePageId,
} from 'components/StoryPlayer/Modules/Order'
import {
  PaymentCurrency,
  PaymentResult,
  paymentCalculator,
} from 'components/StoryPlayer/Modules/Order/paymentCalculator'
import { BlockData } from 'components/StoryPlayer/PageBlock'
import { PageTransformer } from 'components/StoryPlayer/pageTransformer'
import { RegionCode } from 'google-libphonenumber'
import { get } from 'lodash'
import RootStore from 'stores/RootStore'
import { generateId } from 'utils/SeedUtil'

const getPageId = (value: string) =>
  generateId('testQualificationSummary' + value)

const testQualificationSummary: PageTransformer<{
  contactPhoneNumber: string
  currency: PaymentCurrency
  labId: string
  orderPaymentMethod: OrderConfig['orderPaymentMethod']
  orderableTestOptions: SelectOption[]
  schemaResults: PatientAppOrderableTestDisqualifyingQuestionsSchemaResult[]
  testSelectionPageId: string
}> = ({ returnToPageId, options }) => {
  const {
    contactPhoneNumber,
    currency,
    labId,
    orderPaymentMethod,
    orderableTestOptions = [],
    schemaResults = [],
    interpolationData,
    testSelectionPageId,
  } = options
  const phoneCountryCode = interpolationData.account?.settings
    ?.fieldFormatting?.phoneCountryCode as RegionCode
  const formattedContactPhone = formatTel(
    contactPhoneNumber,
    phoneCountryCode,
  )
  const questionnaireResponses =
    options.questionnaireResponses.current
  const isPatientPayMethod = orderPaymentMethod === 'patient-pay'
  const { disqualifiedTestOptions, qualifyingTestOptions } =
    schemaResults.reduce<{
      disqualifiedTestOptions: SelectOption[]
      qualifyingTestOptions: SelectOption[]
    }>(
      (acc, result) => {
        const { orderableTestId } = result
        const schema = result.schema as FormSchema
        const section = schema.sections[0]
        const schemaName = section.name
        const fields = section.fields ?? []
        const option = orderableTestOptions.find(
          ({ value }) => value === orderableTestId,
        )
        const isDisqualified = fields.some(
          (field) =>
            get(
              questionnaireResponses,
              `${schemaName}.${field.name}`,
            ) === 'yes',
        )
        if (isDisqualified) {
          acc.disqualifiedTestOptions.push(option)
        } else {
          acc.qualifyingTestOptions.push(option)
        }
        return acc
      },
      {
        disqualifiedTestOptions: [],
        qualifyingTestOptions: [],
      },
    )
  const blocks: BlockData[] = [
    {
      content: `Test Qualification Summary`,
      type: 'text',
      variant: 'h4',
    },
  ]

  // Derive orderable test ids from responses and store in OrderModule for referencing.
  // This allows story events to access this data while not needing to persist it to the db.
  OrderModule.orderableTestIds = qualifyingTestOptions.map(
    ({ value }) => value,
  )

  // Message for NOT qualified tests.
  if (!qualifyingTestOptions.length) {
    const contactPhoneText = formattedContactPhone
      ? `\n\nFor any questions you can contact us at [${formattedContactPhone}](tel:${contactPhoneNumber}).`
      : ``
    return [
      {
        connectionRules: [
          {
            conditions: {
              all: [
                {
                  fact: 'questionnaireResponses',
                  operator: 'equal',
                  path: '$.continueWhenNoQualifyingTests',
                  value: 'backToTestSelection',
                },
              ],
            },
            event: {
              params: {
                // Avoid circular navigation issues for calculating story progress.
                excludeFromPath: true,
                pageId: testSelectionPageId,
              },
              type: 'StoryNavigation',
            },
          },
        ],
        defaultConnectionPageId:
          OrderModulePageId.questionnaireFinished,
        fields: [
          {
            beforeBlock: {
              content: `#### Test Qualification Summary\n\nBased on your responses, you do NOT qualify for any of the offered tests.${contactPhoneText}`,
              type: 'text',
            },
            label: ``,
            name: 'continueWhenNoQualifyingTests',
            options: [
              {
                label: 'Continue',
                value: 'continue',
              },
              {
                label: 'Back to test selection',
                value: 'backToTestSelection',
              },
            ],
            selectionHidden: true,
            type: 'button',
          },
        ],
        id: getPageId('summary'),
        type: 'form',
      },
    ]
  }

  // Message for qualified tests.
  if (qualifyingTestOptions.length) {
    blocks.push(
      {
        content: `Qualifying tests based on your responses`,
        sx: { pt: '0.825em' },
        type: 'text',
        variant: 'h5',
      },
      {
        content: `${qualifyingTestOptions
          .map(({ label }) => `- ${label}`)
          .join('\n')}`,
        sx: { position: 'relative', top: '-0.825em' },
        type: 'text',
      },
    )
    if (disqualifiedTestOptions.length) {
      blocks.push(
        {
          content: `Tests you did NOT meet qualification`,
          type: 'text',
          variant: 'h5',
        },
        {
          content: `${disqualifiedTestOptions
            .map(({ label }) => `- ${label}`)
            .join('\n')}`,
          sx: { position: 'relative', top: '-0.825em' },
          type: 'text',
        },
      )
    }
    if (isPatientPayMethod) {
      blocks.push(
        {
          content: `Estimated total for qualifying tests`,
          type: 'text',
          variant: 'h5',
        },
        {
          get content() {
            const testOrders = qualifyingTestOptions.map(
              ({ value }) => value,
            )
            const { label, paymentUrl }: PaymentResult =
              paymentCalculator(labId, testOrders, currency)

            // Store payment url to be used in consent complete dialog.
            RootStore.set('recentPaymentUrl', paymentUrl)
            return label
          },
          sx: { position: 'relative', top: '-0.825em' },
          type: 'text',
        },
      )
    }
  }

  return [
    {
      blocks,
      defaultConnectionPageId: returnToPageId,
      enableActionIconButton: true,
      id: getPageId('summary'),
      type: 'custom',
    },
  ]
}

export default testQualificationSummary
